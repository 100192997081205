export const json = [
  [
    "X1433EPSILON",
    0.0312302436785541,
    -0.00918435539327832,
    0.0436857230281218,
    -0.00654393216869088,
    0.0733671593033807,
    -0.0253525817322307,
    0.0466086154461775,
    -0.00835857590792134,
    0.0216919057659884,
    -0.00396435698405196,
    0.0150061032527719,
    -0.0243769062568427,
    0.0211233190779754,
    -0.00824549120257089,
    0.001739792623484,
    -0.0123052402571075,
    0.00748474917840068,
  ],
  [
    "X4EBP1",
    -0.0824995705369163,
    0.0819743434376163,
    0.0194565321857706,
    -0.0522455265122784,
    0.079659615228192,
    0.0156344827508261,
    0.0627469297603328,
    -0.0210409775420935,
    0.0399958180522111,
    -0.0724730393419658,
    -0.0518841013630708,
    0.0244013396582242,
    0.0250938858847968,
    0.0351205733785038,
    -0.0865206633796746,
    -0.0889722479876104,
    -0.0344789109946557,
  ],
  [
    "X4EBP1_pS65",
    -0.0454254438361664,
    0.0327221990929179,
    -0.0202305149051143,
    -0.0309359575473556,
    0.00699990078947765,
    -0.0321496548475915,
    -0.0147209978848047,
    -0.0174811257032821,
    0.0299389960609469,
    -0.0195255795482093,
    -0.0271873614647062,
    0.000983275322921404,
    0.0371839619646794,
    -0.0141653802260749,
    -0.0668139264171745,
    -0.033304070816709,
    -0.0357549907627675,
  ],
  [
    "X4EBP1_pT37T46",
    -0.0817433809237513,
    0.0129303897281646,
    -0.0734717984671053,
    0.0431834890426253,
    -0.166467695644804,
    -0.168749522291622,
    -0.0769288962467536,
    0.0293717853137842,
    0.0473112638066603,
    0.0127691256884004,
    -0.0295934725930694,
    0.110960461823816,
    0.049821132910778,
    -0.0289476970572037,
    -0.0844415544402032,
    -0.0941091103520895,
    -0.0337617088601057,
  ],
  [
    "X53BP1",
    -0.0991708327045142,
    -0.00712227425972817,
    -0.15022055548877,
    0.0844476680912712,
    -0.0264425918621031,
    0.114230586041642,
    -0.0288447677712739,
    0.0662700987474454,
    0.0090336803679861,
    -0.0262529986833498,
    0.0139148998533253,
    0.0804342119213412,
    -0.0404553392478186,
    -0.00622402512393309,
    0.0245029651291773,
    -0.0646260154475239,
    0.0103522604374879,
  ],
  [
    "ACC_pS79",
    -0.111127222385525,
    -0.012549786572272,
    -0.022298276396344,
    0.000253290633234621,
    0.0577077166673153,
    -0.0822001515563853,
    0.0931312180292498,
    0.0485299303057538,
    0.0162212711435969,
    -0.0324942346397445,
    0.0336716304477217,
    0.00342079000786741,
    0.0415281401888968,
    -0.00174368375233559,
    0.0405547224523283,
    0.0345656587232379,
    -0.0906832732142466,
  ],
  [
    "ACC1",
    -0.22219194689963,
    -0.0334027763235784,
    -0.0305484084102175,
    -0.0613290785250416,
    0.0156450812563754,
    -0.0392349305945803,
    0.00760407829185805,
    0.018948608938283,
    -0.0115734291304795,
    -0.0643102368653991,
    0.0925164162573714,
    -0.0229364011610462,
    0.0789408143464805,
    0.0697858219882502,
    0.054003485673279,
    0.0805795701771144,
    -0.126663711430241,
  ],
  [
    "AKT",
    -0.0309966320187181,
    0.154189476197307,
    -0.108072754734118,
    0.0719672511875265,
    -0.0625657993247515,
    0.0457706584512994,
    -0.0468841357578665,
    -0.00100243529019998,
    0.093868981139196,
    -0.0706504141008435,
    -0.0574926011029013,
    -0.0677001775617063,
    0.0490773575890156,
    0.0276378282428988,
    0.113784135885611,
    -0.012821744788886,
    0.0339055608818869,
  ],
  [
    "AKT_pS473",
    -0.00807895211450019,
    0.020366522083367,
    -0.0742422161082555,
    0.00994190778803683,
    -0.097155030298661,
    -0.247220227587448,
    0.163532632549592,
    0.105419493120971,
    0.0581564758168262,
    -0.0130420627832012,
    -0.0643025235324777,
    -0.0241176374768674,
    -0.185571534937508,
    -0.0204947582934696,
    -0.0805777446811524,
    0.126429653120083,
    -0.047324719439107,
  ],
  [
    "AKT_pT308",
    -0.0121300427965541,
    0.0304697317395621,
    -0.0704162789047832,
    -0.0175599325051385,
    -0.0773754635073204,
    -0.247694278456821,
    0.0879457749969849,
    0.0929548846753241,
    0.05891957228643,
    0.00250453942894585,
    -0.073068065242711,
    -0.00386192096210186,
    -0.247940967903427,
    -0.0227819213407117,
    -0.097015549685542,
    0.12889255062226,
    -0.0448929271798034,
  ],
  [
    "AMPKALPHA",
    0.00100933902271227,
    -0.00149595737503962,
    -0.0148369438717067,
    0.0498844420890104,
    -0.0221662588033468,
    -0.0139173455243281,
    -0.0249462254028633,
    0.00488234553318197,
    -0.0272373937019952,
    0.00910950222775403,
    0.0860206715681744,
    0.0130313650230978,
    -0.0342992712296105,
    -0.00932158115836146,
    0.0178656234656988,
    0.0106052926885342,
    0.0277928261721786,
  ],
  [
    "AMPKALPHA_pT172",
    0.0229117015435913,
    -0.0102095459306777,
    -0.00067640028195397,
    0.0894989786500604,
    0.0432576307170747,
    0.0986316357798672,
    0.0397970790679528,
    0.0516399924260737,
    -0.0488796027700424,
    -0.0233410119978926,
    0.028343480987783,
    -0.0872726654344756,
    0.00867785373874754,
    0.0585084687099642,
    0.103301692451939,
    0.0684756032974372,
    0.00232786862064613,
  ],
  [
    "AR",
    -0.0299443402274323,
    -0.112041556613144,
    -0.0511601566116532,
    -0.0604668134139462,
    -0.00162582855443084,
    0.0167950850860158,
    0.0103680670711659,
    0.0341967918422311,
    -0.0141328496412201,
    0.0265653188189182,
    0.000631538654554023,
    0.050370348984017,
    -0.0228651745778859,
    -0.00323774052094924,
    0.0279087076735109,
    0.0361078074725145,
    -0.012155677449148,
  ],
  [
    "ASNS",
    -0.0598403059794562,
    -0.0404794559293469,
    0.0915285258988337,
    -0.0911548885923794,
    0.0656318667251945,
    -0.0122634273752815,
    0.0259798160335108,
    0.08513344890412,
    -0.0927319313323101,
    -0.0128299384479773,
    -0.0177671536404206,
    0.0246124454330701,
    -0.040352208492065,
    -0.0375088199531158,
    0.0718110858023181,
    -0.00966046447261343,
    -0.1123267897771,
  ],
  [
    "ATM",
    -0.0060277294004752,
    0.100264609218202,
    -0.0173169974433444,
    0.0950292867751876,
    -0.00793812413039821,
    0.0071665508019688,
    -0.018586909158443,
    -0.0199078581346848,
    -0.0820864520286593,
    -0.0665945448078111,
    -0.079311639205278,
    -0.0689075040068134,
    0.0814615667938414,
    0.0555802284200943,
    0.133281002070831,
    0.162019063481074,
    -0.0743497415318669,
  ],
  [
    "BAK",
    -0.02045762440376,
    0.0180719924358129,
    -0.0142156936040308,
    -0.066662395477781,
    0.0348871943728303,
    0.0846115518178668,
    0.0319652866781513,
    -0.0345828090686574,
    0.0792754377717646,
    0.0770213787434456,
    -0.00886842736993862,
    -0.0760879879728228,
    0.0185819245279437,
    -0.0580230049192415,
    -0.104664916426779,
    0.0469438324710505,
    0.165548137195921,
  ],
  [
    "BAX",
    -0.0151596068333317,
    0.050431373992242,
    0.0477258883087247,
    0.0339876364234657,
    -0.016774301851023,
    -0.00580344063714299,
    -0.0774812619768318,
    -0.034006985276869,
    -0.0232720212910776,
    -0.00354898633983078,
    0.0389052648223688,
    0.000972259682478903,
    -0.0736153567215678,
    -0.0244638023152319,
    -0.0558942994211921,
    -0.00201933366796988,
    -0.00747623540584742,
  ],
  [
    "BCL2",
    0.121235190327825,
    0.0473684580563868,
    -0.0640946431787703,
    0.0971586694771819,
    0.00910700741511007,
    0.0681554001850946,
    0.0721889467464351,
    -0.162770959787906,
    -0.0485271316850301,
    0.0825484253006574,
    0.0273781581972018,
    0.0406206623179583,
    -0.020171259175972,
    0.14052186710502,
    0.0122580576428456,
    -0.0710043944466002,
    0.0227730122610034,
  ],
  [
    "BCLXL",
    0.010850245203679,
    0.0259711824129718,
    0.0472235680882571,
    0.0226797755139271,
    -0.005243322603966,
    0.0123474630125015,
    -0.00321025794452588,
    -0.0490111529137611,
    0.0261595890581417,
    0.00161777190438084,
    0.0304427034330539,
    -0.0263736001185137,
    -0.043489114677453,
    -0.0417185602185441,
    -0.0628666947694426,
    -0.0443753730047022,
    0.0314810653359515,
  ],
  [
    "BECLIN",
    0.011934473518148,
    0.0206231336177901,
    0.0250888220032661,
    -0.0168260488577581,
    -0.000772903558883343,
    -0.0159305367957061,
    0.0199352384146834,
    -0.0128737046837045,
    0.0102622358014213,
    0.00908793342781389,
    -0.0112064216479985,
    -0.0373998407643898,
    0.0353927750753935,
    0.017645068427049,
    0.00597897314406526,
    -0.0174836506079979,
    0.004960795744385,
  ],
  [
    "BETACATENIN",
    0.0473797044516264,
    -0.0816832113057407,
    -0.0921478875311375,
    0.293211728257203,
    0.0377858790186444,
    -0.0105356246076171,
    0.0489761871514943,
    -0.147449862207046,
    0.0851303640746109,
    0.0152261026351743,
    0.0796442531121939,
    -0.0159871836177271,
    -0.113439123333809,
    -0.0116477108998754,
    0.10282233384461,
    0.127940587222675,
    -0.0422279803621231,
  ],
  [
    "BID",
    0.0198808318783265,
    0.00727884978922923,
    0.0258649056979219,
    -0.0322046534468049,
    -0.0472846789737975,
    0.0290319218742417,
    -0.0487196624919855,
    0.00443378906672032,
    0.0343524111741487,
    0.0706709639375832,
    -0.00921887545425129,
    -0.00209768752641115,
    -0.041541459708177,
    -0.0214192274236972,
    -0.0124688402038437,
    0.023428378174412,
    -0.0689090071638159,
  ],
  [
    "BIM",
    -0.0519241454959063,
    -0.0673223928521186,
    -0.0561513262858627,
    -0.0368569105644255,
    0.0205930742672732,
    0.0901038539091221,
    0.00726475741064188,
    -0.0225895828053259,
    -0.0830152561205624,
    0.0609132202393088,
    -0.0492000577576629,
    -0.0472022661821585,
    -0.0638598362060307,
    0.041882052734727,
    0.0411486350301375,
    -0.0045702588427273,
    0.0554497792035758,
  ],
  [
    "CJUN_pS73",
    0.0153567460186481,
    -0.0212550274685426,
    0.012097164416772,
    0.00123000293558457,
    0.0019021433286371,
    -0.0120150118484922,
    0.0231064751633198,
    0.0306401085989199,
    0.0194458267529512,
    0.0208672212362236,
    -0.0255172535431545,
    0.0286839364744393,
    0.0391833067997011,
    -0.0113969579068823,
    0.0244196512023559,
    -0.0279345185926292,
    -0.00780769820671479,
  ],
  [
    "CKIT",
    0.0841239909994407,
    0.117844287391892,
    -0.115471454094683,
    -0.0026636273602972,
    -0.0482996862837946,
    -0.0804122058793655,
    -0.00232004205856996,
    -0.376534870088324,
    0.135421056053981,
    0.0660438190062288,
    -0.16345655431214,
    0.0187506178641758,
    0.0253300756224162,
    0.314338134380851,
    0.224820573798067,
    -0.2366291294668,
    -0.0987602242918878,
  ],
  [
    "CMET_pY1235",
    0.0048112442380008,
    -0.000672686550945226,
    -0.00163329769865559,
    -0.0276738871093573,
    0.00522607325215787,
    0.014658460632225,
    -0.0177997921058045,
    0.00169052167589754,
    0.0293477442255774,
    0.0223589759273792,
    0.0142638971685795,
    -0.0164688379819914,
    0.0254426604794284,
    -0.00105436527220051,
    -0.0238520161865128,
    0.0136171814813942,
    -0.00641865513763704,
  ],
  [
    "CMYC",
    -0.0372960225603333,
    0.0168253219182051,
    0.033172041268873,
    -0.103616803666628,
    -0.0495235788197897,
    0.00498742199757805,
    -0.0414322799613671,
    -0.0247507330033748,
    0.0949413272159296,
    0.0575149269845339,
    -0.0232575929334235,
    0.00854957663064969,
    0.0146604162050473,
    0.0350844739437386,
    0.045362059394722,
    -0.0157834564956548,
    -0.078238350629984,
  ],
  [
    "CRAF",
    0.00076953819670212,
    0.0087390733678868,
    -0.0352849386607027,
    0.0130948845585001,
    -0.00102580319582369,
    0.043725386740616,
    -0.0241721557367432,
    0.0152588031082536,
    -0.00787855492157756,
    0.0114774472087586,
    -0.011888363095326,
    -0.0121157783258287,
    0.0100156162290366,
    -0.015071045825005,
    0.0226589914337728,
    0.0463599855315211,
    -0.0227240522992039,
  ],
  [
    "CRAF_pS338",
    0.0428713143191976,
    -0.00981356999074048,
    0.00532884770367975,
    -0.0331434823095818,
    0.00736690751706959,
    -0.0153249732763251,
    0.0014747934548986,
    0.0140176108931493,
    0.0359514752721945,
    0.0338538203296811,
    0.000191668414718389,
    -0.0028843671046361,
    0.0191143820124107,
    -0.0431506564704897,
    0.0240859265302608,
    0.0269961592012986,
    0.0285466873499139,
  ],
  [
    "CASPASE7CLEAVEDD198",
    -0.0445471909013032,
    0.107037025267523,
    0.0814580191066759,
    -0.0961877327304521,
    0.0121615937947984,
    0.0387562038729581,
    -0.072902734391637,
    -0.00860530337133515,
    -0.321857262399986,
    0.0288091337253447,
    -0.254342479130037,
    -0.317329797804465,
    -0.0524246521581683,
    -0.0553656612744531,
    -0.231310700801832,
    0.140623870100671,
    0.0901464075794602,
  ],
  [
    "CAVEOLIN1",
    0.0509647211613259,
    0.0166994765468156,
    0.0463061533060666,
    -0.00298699521495134,
    -0.332171406904697,
    0.0798034259099477,
    -0.028321769162451,
    -0.00431760936237305,
    0.0719363926115309,
    -0.0602001190519267,
    0.265499676566737,
    -0.292158876936906,
    -0.00247882548496946,
    0.116976934906418,
    -0.0316236413576398,
    0.00548460551292393,
    -0.0664780085352349,
  ],
  [
    "CD31",
    0.049022416276972,
    -0.00359972861038267,
    0.0484732775871864,
    -0.00602190356939112,
    0.0216224172386247,
    -0.0178486815365549,
    0.00679484559664951,
    0.000198325950443851,
    -0.0293855858725631,
    0.0308761486929241,
    0.00352429154668433,
    -0.0491070374809436,
    0.0302111088454192,
    -0.0162874936755693,
    0.0358492566294786,
    -0.0134151256993237,
    -0.0130349549531505,
  ],
  [
    "CD49B",
    0.0223606883741715,
    -0.0292460645089313,
    0.0625425677868811,
    -0.00287935150684679,
    0.0510060156938475,
    -0.0275471873177669,
    0.0259343388614549,
    0.052133635244352,
    -0.0196137383421622,
    -0.0428980043126888,
    0.049298300535788,
    0.0320475147511206,
    -0.110537672499932,
    0.0526974829709233,
    0.00995238562809776,
    0.000275606186421036,
    0.0431953663158588,
  ],
  [
    "CDK1",
    0.0310603492958083,
    0.00235575536499116,
    -0.0169777936135796,
    -0.0484371033120822,
    0.0142545540839112,
    -0.039501154951062,
    -0.0316358693552169,
    -0.0167043618338392,
    0.0621652236871275,
    -0.013688316233903,
    0.0264454255497271,
    -0.0598623458486207,
    0.0919810664780533,
    -0.0256585651556709,
    -0.0470502199981853,
    -0.0340878270633682,
    -0.0735080313906276,
  ],
  [
    "CHK1",
    0.0352538315112366,
    0.0125094769617724,
    0.0188614495457178,
    -0.025470978128964,
    0.0191143376772245,
    -0.0128133412512673,
    -0.0110943626420049,
    0.00632789305672984,
    0.00850656052086896,
    0.000864901039543138,
    -0.0193138769283808,
    0.0215918883125382,
    0.0074703501486405,
    0.013465770454913,
    0.00669129161455019,
    -0.0249427376774863,
    -0.0575307203722906,
  ],
  [
    "CHK1_pS345",
    0.00671006069330731,
    0.0121861130933314,
    -0.00757646788878637,
    0.0294702083522214,
    0.0179859200357021,
    -0.000687895423515004,
    0.023107614034599,
    -0.00247869205948138,
    0.0017125732874568,
    0.00579786297196301,
    -0.00274004299666992,
    -0.017447652862735,
    -0.00400335071249299,
    -0.0500306494017045,
    -0.00943387069465301,
    -0.0209654405140908,
    -0.00184596344057758,
  ],
  [
    "CHK2",
    -0.069336578325273,
    -0.00491979802948367,
    0.0294433180965942,
    0.00681494403824886,
    0.0528639379473201,
    -0.00306172508028948,
    -0.00352633457453025,
    -0.0329504069613271,
    -0.00996536619843907,
    -0.064302512864405,
    0.0147611481928888,
    0.0221818301586067,
    -0.00195453539144474,
    -0.0187646157879427,
    -0.02273430041357,
    -0.0312313982159881,
    -0.00866176787924651,
  ],
  [
    "CHK2_pT68",
    0.0293526344733621,
    -0.00644125240245544,
    0.0409656289197153,
    -0.0404776572131886,
    0.0492646842364214,
    -0.0180793562401867,
    0.016485596810563,
    0.0105699092334878,
    0.0377723182617701,
    0.0400729014356102,
    -0.0132858713386003,
    0.00375291203524174,
    0.0212371045981384,
    -0.00487793985415662,
    -0.0149012531239687,
    0.00491982587185611,
    -0.024191359399528,
  ],
  [
    "CIAP",
    -0.00416767060791196,
    0.00976775424377591,
    0.00618098074271324,
    0.0147717629449057,
    0.0308533114619199,
    -0.00337238138378373,
    0.0130563887563506,
    0.000804850432212025,
    0.000325368893476779,
    -0.0304843272235359,
    0.0421968747268946,
    0.0101282763802095,
    0.028844978789097,
    0.0514525693953984,
    0.0121505430296815,
    0.026511042898887,
    0.0173523513730148,
  ],
  [
    "CLAUDIN7",
    0.202545488677278,
    -0.288075148679275,
    -0.275032786573366,
    -0.0916711314724381,
    0.0604018676345054,
    0.0497436486743346,
    -0.054008104339722,
    0.0181145680429726,
    0.011751971405501,
    -0.285738467198518,
    -0.284828278413791,
    0.0468164294078809,
    -0.18519240150483,
    0.0242385770375492,
    0.0563569482353926,
    -0.228225048884007,
    0.198345465502554,
  ],
  [
    "COLLAGENVI",
    0.0472261258355134,
    0.0118306578243168,
    0.000571553505302146,
    -0.109370522947238,
    -0.120733798107284,
    -0.000883132087011996,
    -0.0367792169235156,
    -0.0429527085845956,
    0.0558404762671542,
    0.0764310868542939,
    -0.00405305517380966,
    -0.0164615940486497,
    -0.000859605506404515,
    0.126134137152712,
    0.0678356909913492,
    0.0406918375284063,
    -0.0112008681936892,
  ],
  [
    "CYCLINB1",
    -0.210935406711449,
    -0.0116198756025992,
    -0.0713719464656459,
    -0.151889193563368,
    0.100704474296415,
    0.0231920400341665,
    -0.0361943466049526,
    -0.0331130720831432,
    0.0158968257472861,
    -0.151772860460307,
    -0.0488796591769122,
    0.00902550740363402,
    0.102624767443795,
    -0.0882745123220325,
    -0.0681449019348733,
    -0.000651352508964656,
    -0.0788206948940447,
  ],
  [
    "CYCLIND1",
    0.0361601678698412,
    0.00763111707888138,
    0.0010972310640739,
    -0.0290316960652542,
    -0.0115082614353653,
    0.00775284132331641,
    -0.0158226372503262,
    -0.0265410186844253,
    0.0259147388736326,
    0.0177790151968463,
    -0.0174479287297888,
    0.021394015113739,
    -0.0572703807593238,
    -0.00139842989197578,
    -0.0477254145732088,
    0.00458708695918286,
    -0.0415884659107407,
  ],
  [
    "CYCLINE1",
    -0.0683710413801893,
    -0.0188898363011076,
    0.0022492587880255,
    -0.0269698136124814,
    0.0849026555429988,
    -0.0560572706513367,
    0.0117716116621915,
    -0.0349231634769655,
    -0.0299154913628321,
    -0.0423896416250063,
    0.00456971034418319,
    0.0416997548219655,
    -0.00677256624767152,
    -0.0191537907623167,
    -0.00667711939268982,
    -0.0174723761451143,
    -0.0629059567873373,
  ],
  [
    "DJ1",
    0.0162521422833789,
    0.0178336751109917,
    -0.00921066534427386,
    0.0217527150087802,
    -0.0153506255917875,
    -0.0123948220239152,
    -0.0306453915024775,
    -0.0217451638554814,
    -0.0193245463784639,
    0.00928973137381552,
    0.092301543936973,
    -0.00075266534910667,
    -0.00608396987610894,
    0.0301019154438639,
    -0.0128280696696664,
    -0.0176492747452191,
    0.0620401152543104,
  ],
  [
    "DVL3",
    -0.0329963807153856,
    -0.0142832313184223,
    -0.0114410139318536,
    0.047890384403031,
    0.0126000343990493,
    0.064904975606248,
    0.0232119140165936,
    0.0483654469909301,
    0.0268153686734818,
    -0.00293355746894784,
    -0.0412280400862309,
    0.0336920166930788,
    -0.0459698109963815,
    -0.00657331930458217,
    0.0138424816991381,
    -0.0396096453953222,
    -0.0222357540387812,
  ],
  [
    "ECADHERIN",
    0.0229804576439801,
    -0.281551523510551,
    -0.121118076103678,
    0.255843502301594,
    0.0886769901533484,
    -0.230494185155882,
    0.0362775421822708,
    -0.449072124352835,
    0.0792969742463127,
    0.151528001153247,
    -0.07353799763494,
    -0.209144931964123,
    -0.0958394324554723,
    -0.0450698650754795,
    0.0121748416747211,
    0.282094467594833,
    0.0128313483985797,
  ],
  [
    "EEF2",
    -0.0762676854103937,
    0.102947664088902,
    0.0133925568444568,
    0.0452546146673907,
    -0.00960019416085629,
    0.0369312116880211,
    -0.0476037738588551,
    -0.0973476368328801,
    -0.00125641532560885,
    -0.118215118524109,
    0.0927795560897715,
    -0.0293593159610157,
    -0.0943046188171769,
    -0.0664937421178217,
    -0.0782695593253214,
    -0.129676843753436,
    -0.0582244431514754,
  ],
  [
    "EEF2K",
    -0.0583684284600329,
    0.0288319761322763,
    -0.0561844843157432,
    0.0467284648220854,
    0.0187035910661981,
    0.0816497000801751,
    0.0622450277837882,
    0.00562021508678882,
    0.0469287266561775,
    0.0125638239534895,
    -0.0501775718315029,
    -0.00748546464646384,
    0.0174712111716813,
    -0.0471277892643116,
    -0.00792105699506874,
    -0.0627438517680546,
    -0.0156212895394405,
  ],
  [
    "EGFR",
    0.00889218772575904,
    -0.0304846527321384,
    0.0250006299216613,
    0.0360176373584308,
    -0.0575982660354876,
    0.0333245721834953,
    -0.055134037170832,
    0.00646051249335883,
    0.0238950974862438,
    0.0292119577346529,
    -0.0198324791420454,
    0.0147115109152455,
    -0.0229276302177298,
    0.0000804800837121291,
    0.0226448047506562,
    -0.0430065840959725,
    -0.0373045871489377,
  ],
  [
    "EGFR_pY1068",
    0.0515699029413864,
    -0.0653192726902908,
    0.0139265490942023,
    -0.00820868676696844,
    0.0462330821551727,
    -0.0582360601955423,
    0.0795103869069641,
    0.0616873175150197,
    0.0137011989484315,
    0.0113725364073586,
    -0.0395798445523366,
    -0.00789386427618508,
    0.0458801837626665,
    -0.00477131024880471,
    0.0598993816114015,
    -0.0537711038738136,
    0.0474791526560122,
  ],
  [
    "EGFR_pY1173",
    0.0361120906925994,
    -0.00216822562410136,
    -0.0139191642810457,
    -0.018089981471281,
    -0.0298417034410536,
    0.0354690104671892,
    -0.044592246098526,
    -0.00136022617714226,
    0.0731118536211899,
    0.0669210920659851,
    0.0360485187085177,
    -0.0153598019739295,
    -0.0191006316712229,
    -0.0909200916377234,
    0.0367975521389577,
    -0.00556914876480927,
    -0.0164685082481825,
  ],
  [
    "EIF4E",
    -0.081865372836665,
    0.00579332158950532,
    -0.0308860427138664,
    -0.0610370714709707,
    -0.0248036766045357,
    0.0382095248253759,
    0.00423902517919186,
    -0.00664547633511218,
    0.012794888493442,
    -0.0189544625366083,
    -0.0818747687097448,
    0.00775580173941908,
    -0.00107249224512255,
    0.0203138379317506,
    -0.0238462365647147,
    -0.138834145668984,
    0.0343087573571859,
  ],
  [
    "ERALPHA",
    -0.121892647769379,
    -0.237416690957924,
    -0.0328726991892776,
    -0.0751528167656938,
    -0.0256483214850956,
    0.026298346594374,
    0.00107051313429375,
    0.0720238745960684,
    -0.0303311275035305,
    0.0506224608483365,
    -0.0166157957905326,
    0.0257806245246801,
    -0.0607041116759704,
    -0.0346375429680148,
    -0.00111764207579366,
    0.0173187761934194,
    0.0575460274252912,
  ],
  [
    "ERALPHA_pS118",
    -0.0469738601348961,
    -0.0515166333386836,
    -0.0384689489596778,
    0.00347403143600763,
    0.0307254073034387,
    0.0308444703342367,
    -0.00345870640280116,
    -0.000411781260029053,
    0.00676635899987202,
    0.0234031868211962,
    0.0400724661723487,
    0.0240868068476756,
    -0.0178813380585652,
    -0.023400857359653,
    -0.0260599624772137,
    0.0213981288663002,
    0.0196268869074006,
  ],
  [
    "ERK2",
    0.0208009527977685,
    0.0360429579040743,
    -0.00321331741201146,
    0.101945924181908,
    -0.0335065057085851,
    0.0106463204967924,
    -0.0703857036967039,
    -0.0337365147515197,
    -0.00691234181521978,
    -0.0506628481486058,
    0.0612696582638446,
    -0.0410354550742674,
    -0.0095539368771576,
    -0.0385801411289511,
    0.0161626838198002,
    -0.0349540110546263,
    -0.049956761545726,
  ],
  [
    "FIBRONECTIN",
    0.156792097721708,
    -0.0256347481735202,
    0.366151012601794,
    0.198768877263577,
    -0.0642389414884863,
    0.0418904013545648,
    -0.133282982548339,
    -0.00447835451547558,
    0.207405261069528,
    -0.237594231001558,
    -0.311612610153946,
    0.283895280540249,
    -0.114503866558713,
    -0.147158179188348,
    -0.100337024689747,
    0.16693192605296,
    -0.125545669325808,
  ],
  [
    "FOXO3A",
    0.00683548404134904,
    -0.0292039250040514,
    0.0273648207439415,
    -0.0145178830339358,
    0.0324678325808187,
    -0.00105046836279079,
    0.0441199006281601,
    0.0197622650415674,
    -0.00835180870154744,
    0.0277249120133736,
    -0.0248045208113573,
    0.0155683144226518,
    -0.0121130246959411,
    0.0394723299944583,
    -0.0208483272216334,
    -0.00406088590675281,
    -0.0297324400877225,
  ],
  [
    "GAB2",
    -0.0593889463403523,
    0.116731515567636,
    -0.0588391934778096,
    0.0435540588967556,
    -0.0110643997992223,
    0.109659581069372,
    0.0435146375376593,
    -0.0177658255200861,
    0.141935287583786,
    0.00268030068101017,
    -0.123209952492095,
    -0.0234685878862667,
    0.0601068599977106,
    0.0314632934463907,
    0.0100192766314589,
    -0.0672250619980127,
    -0.0458223227786551,
  ],
  [
    "GATA3",
    0.00771845493697525,
    -0.0320340173507375,
    0.0239486074582331,
    -0.0114230408557985,
    0.0203989938899305,
    0.0241012571153134,
    0.0516756296030368,
    -0.0338249385961184,
    0.00512184768590439,
    -0.0159887135822417,
    0.00926325502047062,
    -0.0347960330033106,
    -0.0398445231778131,
    -0.0840114183997254,
    -0.032687656909125,
    -0.0438237453776664,
    0.0738399169107198,
  ],
  [
    "GSK3ALPHABETA",
    -0.0320237583302692,
    0.0157859452517228,
    -0.00494376065904133,
    0.0379366226336298,
    0.00383761378719693,
    -0.00766010987892631,
    -0.009528623695734,
    -0.0177063160405023,
    -0.020394094698012,
    -0.0531096795310616,
    0.0434084266488126,
    0.0371358937556838,
    -0.024001625212899,
    0.0467636235855305,
    0.0189988244274977,
    0.0386287626306001,
    -0.0305529969648752,
  ],
  [
    "GSK3ALPHABETA_pS21S9",
    -0.106414866638145,
    0.0334117020784812,
    -0.167121469773834,
    0.0513170460172062,
    -0.146597693324869,
    -0.161199138982825,
    0.00747395605213548,
    0.096940700229905,
    0.0181749440155697,
    0.00302643132966593,
    -0.0691278632378864,
    0.0124605928455107,
    -0.05729526158044,
    -0.0523551225705491,
    -0.0192215754172553,
    -0.0368874880402863,
    0.0486290908539532,
  ],
  [
    "HER2",
    -0.0521620297881377,
    -0.118898130267841,
    -0.112839395538898,
    0.0750694746385853,
    -0.0584263490859668,
    0.0851341367353291,
    -0.0340146155006559,
    0.0727205743275805,
    0.0890713886351827,
    0.0380485787529311,
    0.0212307939442796,
    0.0343411963459478,
    -0.084245849634664,
    -0.0787398419933249,
    0.068335814066318,
    -0.0494126364453937,
    0.0500458296696966,
  ],
  [
    "HER2_pY1248",
    0.0324235685647191,
    -0.0606436898049301,
    -0.0626142972479862,
    -0.0382272932056077,
    -0.0251671707549411,
    -0.00268908866982554,
    -0.0147150981419907,
    0.053363310595988,
    0.0139373874653799,
    0.0223924094952631,
    -0.0247433474897354,
    0.027796973160068,
    -0.00605606279171199,
    -0.0240953189405422,
    0.059346429049745,
    0.0199427226815561,
    0.0284296773743262,
  ],
  [
    "HER3",
    -0.00446189457330712,
    0.124745522234314,
    0.0201038481894889,
    0.0287136063937543,
    0.0652946679458804,
    0.0627816879733695,
    0.0838718787816258,
    -0.0118021501748169,
    0.243809282412734,
    0.00689473246871495,
    -0.00437071340548801,
    -0.115822291589409,
    0.106179995742351,
    -0.108363098914101,
    -0.0344282861210462,
    0.0485026585056989,
    0.168874518425864,
  ],
  [
    "HER3_pY1289",
    0.0258292545809008,
    0.00598219606899149,
    0.000260605957490342,
    -0.0222713209109224,
    0.00746808900411895,
    0.0133019132554539,
    -0.0083328176003952,
    0.00038488643833691,
    0.0114723926780326,
    0.0189131620612718,
    -0.00299786708880909,
    0.00665090611021615,
    -0.00830712393166394,
    0.00759108893302916,
    -0.00870906148717871,
    0.0286984163800824,
    -0.0286333526726738,
  ],
  [
    "HSP70",
    0.0846222408111488,
    0.0108908873960041,
    0.118867619204463,
    -0.0701703279837281,
    -0.166190149294694,
    0.076856071533155,
    -0.142352778212098,
    0.0317160069701125,
    0.114455291202192,
    0.265618878161578,
    -0.112843561104918,
    -0.0269902473993907,
    -0.148441709997801,
    -0.143111815351252,
    0.124672015111134,
    0.0828223105007587,
    -0.300166461280112,
  ],
  [
    "IGFBP2",
    -0.13342817948063,
    -0.0553770987187898,
    0.002938666982837,
    -0.107270731659491,
    -0.0140014489957075,
    0.0816000810532033,
    0.0419411503990481,
    0.246767782352332,
    0.175234470445151,
    -0.0681064733532695,
    0.0166398733997625,
    -0.0457269199228623,
    -0.263794133832016,
    0.0178189194715095,
    0.0271662762775315,
    0.0843719281228781,
    -0.011063924161812,
  ],
  [
    "INPP4B",
    -0.0123868667172068,
    -0.1016562295619,
    0.0197980241434606,
    -0.0370551799607708,
    0.0641507504897825,
    -0.010159347899464,
    0.0613327551850097,
    0.0258713004473347,
    -0.00311805203155402,
    -0.044100518973959,
    -0.0322599671735719,
    -0.00626204689509614,
    0.01108691636825,
    -0.0180542597087555,
    -0.00407332926069548,
    -0.0374779414073397,
    -0.00427208374330345,
  ],
  [
    "IRS1",
    0.0446770290193856,
    -0.0227302291542791,
    -0.0324461855071957,
    -0.0450492228509854,
    0.0392810782346167,
    0.0379895885121702,
    0.0101336174017004,
    0.0269739408215658,
    0.0359561747199102,
    0.0230234412363115,
    -0.016228950641181,
    0.0247973548949962,
    0.0153774021318337,
    -0.0136054668859418,
    0.0311633577582964,
    -0.0119892865806689,
    0.0495625621066374,
  ],
  [
    "JNK_pT183Y185",
    0.0647359026207357,
    -0.0354626682196677,
    0.0204451196540318,
    -0.00578825792810477,
    0.00861748390845429,
    -0.0392786538562325,
    0.00716531322026327,
    0.0421861691275692,
    -0.000854654025250434,
    0.0249098403332303,
    -0.016306533676142,
    0.0384667950119547,
    0.0226312285892605,
    0.00365164512936326,
    -0.00232804516382991,
    -0.00538422748932936,
    -0.0386855775792029,
  ],
  [
    "JNK2",
    0.0429945734028385,
    -0.0220092380739813,
    -0.044585616052194,
    0.037994001834438,
    0.0100798004195929,
    0.0592791408279815,
    0.0236345167339183,
    0.0178807717045568,
    -0.0224019908161948,
    -0.0351661102518408,
    0.0292857318995582,
    -0.0400059116554561,
    -0.0415678168442636,
    -0.00952915000326127,
    -0.0309752430748961,
    -0.0588629854305527,
    -0.0550307504875067,
  ],
  [
    "KU80",
    -0.0701931255426565,
    0.0164191158102235,
    -0.0738987891962278,
    0.0667939189424702,
    -0.0135219072168916,
    0.0851987100694156,
    -0.053225277377762,
    -0.0215608908103445,
    0.0276090233239177,
    -0.0575185096102129,
    0.0101004523272884,
    0.0450412974139019,
    -0.00558116126631115,
    0.0114433245534433,
    0.0713473032416421,
    0.0148814801166069,
    -0.0437537235591724,
  ],
  [
    "LCK",
    0.00786494645014505,
    0.049035386097361,
    0.0476587165210941,
    -0.0309099845320396,
    -0.068306393962346,
    0.0749444396680142,
    -0.0390355130664771,
    0.00186446818362688,
    -0.168801928309248,
    0.0417231477933594,
    -0.146465101918015,
    -0.190997438007831,
    -0.0330620573394061,
    -0.0638089581158108,
    -0.0722703890941669,
    -0.0370308301206814,
    0.00420856962520745,
  ],
  [
    "LKB1",
    0.102599502406737,
    -0.01341997315838,
    -0.0467486048908168,
    -0.0577419121555575,
    0.028705027808222,
    -0.00866634120239594,
    -0.0296112709403291,
    0.0150464684952554,
    -0.0186495465758602,
    -0.0348686538548035,
    -0.00170624097137702,
    0.0120578852248397,
    -0.0574663796881636,
    0.0323313951136957,
    -0.0179296339853715,
    0.0219894637096372,
    -0.0557971227400986,
  ],
  [
    "MAPK_pT202Y204",
    0.0423169353070845,
    0.0115535575432214,
    -0.0206346369309122,
    -0.0546460682753088,
    -0.124671695553281,
    -0.170367823606959,
    0.0805890518540443,
    0.0566316612662348,
    -0.00316414474680341,
    0.0222384850380356,
    -0.0174912148439079,
    0.070616571982156,
    0.125481725081842,
    -0.0623696857618916,
    -0.0807224214344373,
    -0.0129641531093862,
    0.0772720673537362,
  ],
  [
    "MEK1",
    -0.0227645389171483,
    0.0379686407469264,
    0.0103576934957201,
    -0.0573964176052879,
    0.000873885678756985,
    0.0390666648142065,
    0.123114106810852,
    0.0164955270084682,
    0.0151514016512182,
    -0.0242916530881948,
    -0.096389406831609,
    -0.0402608638415491,
    -0.00218762416564328,
    0.0226695618646751,
    -0.0365808837859933,
    -0.178684601153589,
    -0.0445982959283249,
  ],
  [
    "MEK1_pS217S221",
    0.0409859882425021,
    0.0548859922230642,
    0.046198390504324,
    -0.0231660412931444,
    -0.0415715855364463,
    -0.0853593521232295,
    0.00275703540465022,
    0.0343605819966382,
    0.0330519500156333,
    0.00366438355026187,
    0.00906822232874184,
    0.00871348268115453,
    0.0406038450178476,
    -0.0234721308243902,
    -0.0775795377217578,
    -0.0282915963596696,
    0.0243536051686119,
  ],
  [
    "MIG6",
    0.0439621578114472,
    0.0130445167450012,
    0.0155638253779153,
    -0.0194685433883036,
    0.0169936583859872,
    0.00967470311334815,
    -0.0378126856722548,
    -0.00915946742106109,
    0.0120022081565282,
    -0.0171055586014169,
    -0.00773213026275033,
    0.0335672190109903,
    -0.0475034909824535,
    0.0127310651575582,
    0.0416655730370029,
    0.0118458911297861,
    0.0307297065863388,
  ],
  [
    "MRE11",
    0.0360335117369897,
    -0.00897236654035085,
    0.0275233457747159,
    -0.0320954573692192,
    0.0112963485517969,
    0.0054188397539599,
    -0.00273378612972791,
    0.0117049837121362,
    0.0341295427565527,
    0.0422945627979653,
    -0.00701527539614486,
    -0.013914988765413,
    0.0230084379547586,
    -0.0232222997982589,
    0.0160594297031583,
    0.0319093272987767,
    0.0048765940204696,
  ],
  [
    "MTOR",
    -0.0200525160049787,
    0.0419880902542596,
    -0.0596466054078263,
    0.088329547962692,
    0.00664103653221638,
    0.0225816687742488,
    0.00446721640367193,
    0.0015110738505979,
    -0.00497503403568783,
    -0.0268171129369742,
    -0.0136420476353385,
    -0.0132882757837355,
    0.00500244672676764,
    0.0252202187962292,
    0.0706535169636238,
    0.0305355765233256,
    -0.04325086602236,
  ],
  [
    "MTOR_pS2448",
    0.010991478865992,
    0.0320218844970097,
    -0.0187120272125432,
    0.0350647559044878,
    -0.0256751084954718,
    -0.0628110966915984,
    0.0449322040413666,
    0.012518043091745,
    -0.00546092730590163,
    -0.00897658881981536,
    -0.00266441326234933,
    -0.0178405231675333,
    0.0071516598401886,
    -0.00786670247686069,
    0.0263627733839233,
    -0.00974872012148489,
    -0.0151400612329253,
  ],
  [
    "NCADHERIN",
    0.0795683581057175,
    0.0229206516462961,
    0.0372289282434643,
    -0.00733268372571276,
    -0.00753140732709055,
    0.0342276155138508,
    -0.0157514946918853,
    0.0125957132164215,
    0.025658978482643,
    0.0796928780327159,
    0.0238206455536667,
    0.0203942209360689,
    -0.0773571680362881,
    -0.0155519295042319,
    -0.0350640619693325,
    0.0189575022625192,
    -0.0948624051789531,
  ],
  [
    "NFKBP65_pS536",
    -0.0417221708294743,
    0.0344351001839108,
    -0.0644447663362101,
    0.046549837706982,
    -0.0544723751027739,
    -0.00149396834727324,
    0.0512575530864096,
    0.0938338801954656,
    0.0252816912181754,
    0.0770965095071275,
    -0.260314948571087,
    -0.0861404870186922,
    0.0158645624146573,
    -0.0801479748592069,
    0.00468884044504108,
    -0.110626331659747,
    0.124408601348009,
  ],
  [
    "NF2",
    -0.0273213497420004,
    0.0502710616269227,
    -0.00956906049782456,
    0.0707058807055632,
    -0.019464874876357,
    0.033210857146266,
    -0.0218097831907265,
    -0.0684788034376719,
    -0.0277843530091452,
    -0.0486628389984876,
    0.07581632231033,
    0.00371408136212632,
    -0.0944340580863766,
    -0.0641640864922658,
    -0.0619911260494116,
    0.00288699813167858,
    -0.0308603144401326,
  ],
  [
    "NOTCH1",
    0.0131306375203158,
    0.0180262961496554,
    0.00779118369226665,
    -0.0150754660558362,
    0.0358317756439996,
    0.0323405858427814,
    0.0546110015016913,
    0.0447009354620416,
    0.0481590142814768,
    0.0313233486901075,
    -0.044272539162861,
    -0.0122369512081469,
    0.00861093764544707,
    0.0206032134595056,
    0.0288542937210989,
    -0.00492612717240145,
    -0.046309754595992,
  ],
  [
    "PCADHERIN",
    0.018583617398301,
    -0.0121575077570718,
    0.0037930101966486,
    -0.00829851416392202,
    0.0238339130115893,
    -0.00162464328147222,
    -0.0109746156359506,
    0.00499230985625641,
    0.0149960926943083,
    0.00251708855911491,
    0.0485068279537524,
    -0.0180015255214271,
    0.0223242847468834,
    -0.00370461160937103,
    0.0382172742989819,
    0.00982049441859437,
    0.0361728212399907,
  ],
  [
    "P27",
    0.0235217888598671,
    -0.0173413224084672,
    -0.014843823462312,
    -0.0312104971629547,
    0.0260875424235971,
    0.0297486717707935,
    0.0528326272097355,
    0.0124360918951995,
    -0.0570079973985971,
    0.0167575113144561,
    -0.0200167643289036,
    -0.0258558452788241,
    -0.0107341234954386,
    0.0543071863374821,
    -0.0736670543882444,
    -0.00388054925143272,
    0.0187475359388358,
  ],
  [
    "P27_pT157",
    0.0250366623112756,
    0.00804290745589294,
    -0.0119972920717112,
    -0.0243957488527791,
    0.00878267670582631,
    0.0102031625804249,
    0.00579318246823239,
    0.0060093590596917,
    0.0231030765436113,
    0.00959336706619165,
    -0.00247065992596741,
    -0.00558057467226421,
    0.00938751071153062,
    -0.0264000269081789,
    0.00224995627805174,
    -0.0065266588756474,
    0.00133329221309584,
  ],
  [
    "P38MAPK",
    0.0444635719069851,
    0.0244634419866163,
    0.0333065655389736,
    0.0736256292510471,
    -0.00485990782382788,
    -0.011956908293265,
    -0.0401850179738897,
    -0.0167315843686243,
    -0.0806390932731612,
    -0.0181699871731607,
    0.00541736650988009,
    0.0112657325352168,
    0.0320007706250171,
    0.0378878296299935,
    0.0101162652556526,
    -0.00439081355054943,
    -0.00696569270639167,
  ],
  [
    "P38_pT180Y182",
    0.0535359692127158,
    -0.0155508868865335,
    -0.0265361028565063,
    0.122395436045752,
    -0.142996079128044,
    -0.106841811032055,
    -0.0991569266444682,
    0.0849394229764602,
    -0.130669111489683,
    0.0381922774047005,
    0.0334564872534194,
    0.0599269560344497,
    0.0198299108619539,
    -0.0231606793315054,
    -0.00742242659684321,
    -0.0338976915053548,
    0.0430656751247723,
  ],
  [
    "P53",
    0.0208245414700988,
    -0.0170011506728699,
    0.0173824144740207,
    -0.0486439559512912,
    0.0531539705678808,
    -0.0168872533245681,
    0.0224346139438865,
    0.0311143426992127,
    0.0274230699127256,
    0.0105019702252063,
    -0.0234550588050548,
    -0.0338792892204437,
    0.036645716138362,
    -0.0290972932747953,
    0.0554889759474485,
    -0.0267140616712208,
    -0.00240822378438149,
  ],
  [
    "P70S6K1",
    -0.050395583175103,
    0.0306599672203399,
    -0.0123597399013533,
    0.0274809047877553,
    0.013526920151989,
    0.0236583788304741,
    0.0333181919433715,
    0.0162945860469687,
    -0.0216424165283654,
    -0.019806373223709,
    -0.00409426411051854,
    -0.000826026325422029,
    0.00130624344551501,
    -0.0102059614290028,
    0.0169640250023932,
    -0.00993088159655757,
    -0.00648990797641278,
  ],
  [
    "P70S6K_pT389",
    0.0472956455433797,
    -0.0119401958218797,
    0.0756333022564169,
    -0.0229937144263285,
    0.0393508721482069,
    -0.055787956817793,
    0.104878977672809,
    0.028238998066843,
    -0.00772652840957419,
    0.0215221309358495,
    -0.0262930780383831,
    0.00213057917606276,
    0.0277457450195449,
    -0.0325812219394075,
    0.0176250312765532,
    -0.0305861959001029,
    -0.0440597058304513,
  ],
  [
    "P90RSK_pT359S363",
    0.0298902680767517,
    -0.0168744205891151,
    -0.0204395867544624,
    -0.0147986345145858,
    0.00541546539927912,
    -0.0468424309846169,
    0.00314894665663132,
    0.046857246182779,
    -0.0303687100032616,
    0.0378784182541891,
    -0.0220714106248433,
    0.0688172845054714,
    -0.011743501631376,
    -0.00882179048615854,
    0.0284113157207167,
    -0.0022665611923851,
    0.00240484533175587,
  ],
  [
    "PAI1",
    -0.00175070521056849,
    0.137088593444004,
    0.16644687553586,
    -0.152899041484729,
    -0.0703154977708441,
    -0.12164697313977,
    -0.260527943688212,
    -0.0480111912204776,
    -0.0241262470723616,
    -0.207420555810471,
    -0.0559205128820137,
    0.0324090636672246,
    -0.127871470734175,
    0.186868318018908,
    0.368160352941536,
    0.0558468884364243,
    0.359734002707024,
  ],
  [
    "PAXILLIN",
    0.0448303172638954,
    0.100963171517024,
    -0.0146269268842884,
    0.155756377469427,
    -0.0061480175716194,
    0.0571117836156474,
    0.031974961140024,
    -0.0125003772640396,
    0.0757206585740443,
    -0.0698867946872873,
    -0.0315806342110878,
    0.0583958553038479,
    -0.0443476721669004,
    -0.0400267995544419,
    -0.0129893007035379,
    -0.0590578746899113,
    -0.0267599071635015,
  ],
  [
    "PCNA",
    -0.0506203095060984,
    -0.00637567608273198,
    -0.00958581834146984,
    -0.0415861006141658,
    0.0418130994930771,
    -0.0103090410071408,
    -0.0255703488780113,
    -0.0517027913431782,
    0.00176153583187168,
    -0.0599222601820089,
    0.0406210535365025,
    0.00441582441900256,
    0.0330761123954247,
    -0.0352418997610009,
    -0.0278877269442523,
    -0.0493662682981735,
    0.0259994693783633,
  ],
  [
    "PDK1_pS241",
    -0.0291248122523168,
    0.0216467667442143,
    -0.0067225549126732,
    0.0481643668177654,
    -0.00691952512366212,
    -0.020611241118757,
    -0.0207111855221096,
    0.00519685711322536,
    -0.0608471843510448,
    -0.0271277236049489,
    0.0131764316725938,
    -0.0240452577296648,
    -0.00333354590100159,
    0.0336919172025804,
    0.0427977845733682,
    0.0211189833797124,
    -0.0263896017313811,
  ],
  [
    "PEA15",
    -0.0303753838118013,
    0.0171220623967513,
    0.0194958421204633,
    -0.00233453224683375,
    -0.0564438162196713,
    0.0207040504563023,
    -0.0632640845519507,
    -0.0209026003574409,
    -0.000662000193056096,
    -0.0169796354988137,
    -0.00149888543378454,
    -0.0139762900357137,
    -0.0133109643829366,
    0.0104560551392663,
    -0.0304747307695446,
    0.0188307211288465,
    0.00780659158515604,
  ],
  [
    "PI3KP110ALPHA",
    0.00368977981227265,
    0.0172066064597759,
    0.0458925687415074,
    0.033780347342348,
    0.0501269818513013,
    -0.0089166430459539,
    0.0443350619098103,
    0.0448009923370661,
    -0.00841751853111535,
    -0.0368774776025415,
    0.00175057382127931,
    0.0162964147473793,
    0.0444406351373078,
    0.0546442852589002,
    0.0341696419313383,
    0.0152262452292046,
    -0.0226113936085488,
  ],
  [
    "PKCALPHA",
    0.024711140386679,
    0.062253740483115,
    -0.0128711713145579,
    0.0192522591372837,
    0.01089011103851,
    0.104119231685982,
    0.186547506297016,
    0.14971302780947,
    -0.0162718761481057,
    -0.0268967415808524,
    0.0646708009952723,
    -0.0652874518929255,
    -0.115398089371695,
    0.167774147572301,
    0.0753835698255367,
    -0.0615427926028754,
    -0.169880701844502,
  ],
  [
    "PKCALPHA_pS657",
    0.0188463334131319,
    0.0480550374916637,
    0.0135676978370805,
    0.0199476278251429,
    0.0282599131392509,
    0.0638405697516028,
    0.178822414719191,
    0.175379755414117,
    -0.0620816853047886,
    -0.0274091454934479,
    0.085233683707,
    -0.0241385576494106,
    -0.108103876215139,
    0.134857858999436,
    0.13414551925152,
    -0.0326487968955883,
    -0.130860359534334,
  ],
  [
    "PKCDELTA_pS664",
    0.0513259646069149,
    0.0208636962368427,
    0.0210084028015155,
    0.0556087562281547,
    0.0374373273324554,
    -0.0110075495106428,
    0.0542656136469549,
    0.032424039478189,
    -0.0591021964360304,
    0.0191788536528551,
    -0.0166192790208584,
    0.0190516240307581,
    -0.0390320964666418,
    0.00881815714568009,
    0.0304231245286343,
    0.00580453551790328,
    -0.0285956156675594,
  ],
  [
    "PR",
    0.00762010719572298,
    -0.0217178795923333,
    0.0148204466856609,
    -0.025574743970269,
    0.0289107428233159,
    0.00565977669564344,
    0.0353411164299746,
    0.00328256501889323,
    0.00464306928395639,
    -0.00110899562378394,
    -0.00135332431702768,
    -0.0000897441517495001,
    -0.00921259827180477,
    -0.00929498279242935,
    -0.00278233564578429,
    0.00136283223994652,
    -0.0136060418342105,
  ],
  [
    "PRAS40_pT246",
    0.0166202285518046,
    -0.00575717442273497,
    -0.0802840807468024,
    -0.0149498702676425,
    -0.0235256521180604,
    -0.0371037426989441,
    0.0338968054491524,
    0.0233699867668679,
    0.0426403825814427,
    -0.0234893675991184,
    -0.0587879638367889,
    0.00626374179353405,
    -0.0328803371750782,
    -0.0197800790058633,
    -0.0304804028991458,
    -0.0118719041328479,
    0.0484492534621325,
  ],
  [
    "PTEN",
    0.0360272235959701,
    0.0358204156659834,
    -0.053387814849322,
    0.0949879985610164,
    -0.0043369330317493,
    0.0595051348002198,
    -0.000999456549754999,
    -0.0177164559147441,
    -0.0934661355377067,
    -0.0182707637760994,
    0.00126076153266584,
    0.00776933144505164,
    0.0829089353636153,
    -0.00718479226533618,
    0.0409390046573938,
    -0.147483660944392,
    0.0740633557699634,
  ],
  [
    "RAD50",
    -0.00937319361710655,
    0.0150746453346771,
    0.0204087909887336,
    0.0602709076534493,
    -0.038795079177844,
    0.0651573390118878,
    0.020371985329871,
    -0.0772865742023924,
    -0.0471747463578391,
    -0.0333535250103933,
    0.146643914582636,
    0.0616801267999323,
    -0.095435949528525,
    -0.0345642039987414,
    -0.0919816738166464,
    -0.0269227695618653,
    -0.0265163594403969,
  ],
  [
    "RAD51",
    0.0374833037083038,
    0.00206978588978495,
    0.0189999312027669,
    -0.0482479397846371,
    0.0176274458044457,
    0.0065471712581175,
    -0.00451442782388646,
    -0.037071804353269,
    0.0547954286683504,
    -0.00327770328716004,
    0.0034320211642436,
    -0.0216577486833907,
    -0.0195142066991906,
    -0.064226930467602,
    -0.0382474996193503,
    -0.0235057121911107,
    0.0285246768315097,
  ],
  [
    "RB_pS807S811",
    -0.0729381867303759,
    0.0515766914026249,
    -0.0690668939058134,
    0.0813742855053871,
    -0.0395323271477395,
    -0.100783510326167,
    -0.0275898902256958,
    0.0823894808326495,
    0.084169936902348,
    -0.0786721406598703,
    -0.0493357679527667,
    0.0999125037751629,
    -0.0193733424681502,
    -0.0673719484603895,
    -0.0280478237616431,
    -0.049149843874487,
    0.000680001574270204,
  ],
  [
    "S6",
    -0.0760024927809511,
    -0.0175291838172575,
    -0.0343777118779813,
    0.0620696796157683,
    0.0607855867324605,
    0.00897953911336876,
    -0.0131015336119344,
    -0.008328250223878,
    -0.0598675004225411,
    -0.0381804998309322,
    0.0299485757353061,
    0.0425583172436508,
    0.0363401989007346,
    -0.0630358777641125,
    0.0403405492571875,
    0.0932927106065253,
    -0.000961553355078445,
  ],
  [
    "S6_pS235S236",
    -0.0213812655325688,
    0.0108510782913049,
    -0.0555137205510744,
    0.00612356903928427,
    -0.0848180308862423,
    -0.141554362091473,
    -0.00718144205078504,
    0.0226940173950981,
    -0.15944599920398,
    -0.0394062120794362,
    -0.0857275353473108,
    0.118919129746861,
    0.147158236714845,
    -0.144116611178777,
    0.0791181589374079,
    -0.0303317828239917,
    -0.11504895373946,
  ],
  [
    "S6_pS240S244",
    0.00793677694228939,
    0.0423684955869595,
    -0.0145435679463103,
    0.0106266960687907,
    -0.0437847007171904,
    -0.14546179563839,
    0.0470622514263504,
    -0.0341671679449533,
    -0.125597004834603,
    -0.0675364030359821,
    -0.121414331038684,
    0.116584021436949,
    0.0970696183843626,
    -0.10980296667395,
    0.0574671482315999,
    -0.0197978551111414,
    -0.148868534976681,
  ],
  [
    "SHC_pY317",
    0.0538669817392321,
    -0.0491623588719885,
    -0.00909933789093385,
    0.0100164217383363,
    0.052104669864162,
    -0.046989170652162,
    0.0450213468199302,
    0.0552043716021649,
    -0.0154615353224402,
    -0.0175247819321736,
    0.0131008448236705,
    0.0191996187731274,
    0.0798259491517602,
    -0.0159622719566958,
    0.0457666522334145,
    -0.0135914866329754,
    0.0349167078048726,
  ],
  [
    "SMAD1",
    -0.0931241683355003,
    -0.0494110615523978,
    -0.0430071893641997,
    -0.0416386963776834,
    -0.0396353558461015,
    0.0571774373264004,
    -0.0263009571064964,
    0.0190321028147726,
    -0.0100388546995902,
    0.013173615022547,
    0.023449942484555,
    0.00582588058997286,
    0.0192834789732862,
    0.0180907788453692,
    -0.00177821060884817,
    0.039418507719018,
    0.0105228857025689,
  ],
  [
    "SMAD3",
    -0.0267853352675398,
    0.0155600112532186,
    -0.0475295143347521,
    -0.0138476555373132,
    -0.0321295534487502,
    0.0781269368869248,
    -0.0557976724112333,
    -0.0248663667104392,
    0.0353291556333141,
    0.0237826807108704,
    0.0417893078696401,
    -0.0198757756975298,
    -0.023472517554792,
    -0.0374762580560796,
    -0.0682720996966813,
    -0.00789373091023169,
    -0.00411261455294442,
  ],
  [
    "SMAD4",
    -0.0143927258813704,
    -0.0132200301007543,
    0.00445530392525436,
    -0.019557361379845,
    0.0171988393649208,
    0.0118789995412492,
    -0.000125240122726865,
    0.0163601717298973,
    0.0226034600586088,
    -0.00609154303257334,
    0.0158624146970705,
    0.0137072205278141,
    0.0173388848206684,
    -0.00059441264728741,
    0.0266311680613173,
    -0.0259516253733711,
    0.050405056497012,
  ],
  [
    "SRC",
    -0.0316677380721844,
    0.0132549532927557,
    -0.00177354498457456,
    -0.0144914775639554,
    0.0238606809686797,
    0.00873320293911371,
    -0.0107583611148845,
    0.0114906276080466,
    0.00120495817428765,
    0.0194193271709096,
    -0.031122061698931,
    -0.0346355107803204,
    0.0304290033130435,
    0.0293141636321528,
    0.0341136866212008,
    0.082735031779156,
    -0.00407516216065073,
  ],
  [
    "SRC_pY416",
    0.0334581874477829,
    0.0122214438981028,
    -0.0517981256844187,
    -0.0193044282301798,
    -0.0199725101931831,
    -0.0588112822560297,
    0.00584867171131486,
    0.065635652568064,
    -0.05220622099104,
    0.00491237396090356,
    -0.0995806968455409,
    -0.0149584134942602,
    -0.000911653417480502,
    0.0288829159632351,
    -0.00414591020024652,
    -0.0435510255319022,
    0.00959066500907783,
  ],
  [
    "SRC_pY527",
    0.0147349414368569,
    0.0254815166306754,
    -0.0796860395138016,
    -0.0256148254727322,
    -0.140936500873183,
    -0.158946475997111,
    0.0867867667698159,
    0.024562397675373,
    0.0577726249245582,
    0.0576575387546577,
    -0.0279552957855325,
    -0.0118474329435123,
    0.0852908011106392,
    0.0543241059519817,
    0.050322635822663,
    0.0156810088479029,
    0.0423959091019078,
  ],
  [
    "STAT3_pY705",
    0.0437685486315195,
    -0.0309824077942027,
    -0.00733941685284099,
    -0.0227966517859499,
    -0.0956643359402394,
    -0.0232892089463615,
    0.00125608708553622,
    0.0526826095735545,
    -0.0549231974976624,
    -0.0546889558287086,
    -0.0220663647483635,
    0.0105145345884706,
    0.0244540936011076,
    0.0382477513412801,
    0.0335200878164228,
    0.0545564835906262,
    -0.0130017709722135,
  ],
  [
    "STAT5ALPHA",
    -0.00738722647847428,
    0.173962659583106,
    -0.104501912377715,
    0.165282060896905,
    -0.0221351980742283,
    0.199051605594593,
    0.084688038108862,
    0.015710812378236,
    0.0138618641183221,
    0.0165798294312815,
    -0.21514206651338,
    -0.200895788146229,
    0.0335003745034853,
    -0.0852928691121328,
    -0.00816104002185088,
    0.00128221024089105,
    0.0143425993269133,
  ],
  [
    "STATHMIN",
    0.0319824912791396,
    0.00231494385730813,
    0.00700708123426308,
    -0.069576683092457,
    0.0101488428213231,
    0.0144434255210952,
    -0.0317246028467729,
    0.013673884656738,
    0.0272528493117351,
    0.0379816183458504,
    -0.0276158784022932,
    0.0057793065761059,
    0.0136858570280179,
    0.00439310724779682,
    -0.011176174116303,
    0.033892351999203,
    -0.0192270324881031,
  ],
  [
    "SYK",
    0.0264824404947151,
    -0.0232553129454008,
    -0.00232020551227189,
    0.0886596804929589,
    0.00382298539285888,
    0.0385183175517162,
    -0.0920755359625144,
    0.0627726099056983,
    -0.299774054694569,
    0.0126147074346302,
    -0.127858379207351,
    -0.107339947539899,
    -0.0978065479053027,
    -0.109078281606118,
    0.160226548116255,
    0.082388743443199,
    0.0377512254764132,
  ],
  [
    "TUBERIN",
    -0.0353717206778842,
    0.0515449058333059,
    -0.0821931933494465,
    0.0565720984033032,
    0.00702551676245371,
    0.0635094691401023,
    0.0344828755633891,
    0.0152126197979089,
    0.0215626472321778,
    -0.0137288171841045,
    -0.0961940715243958,
    -0.0314831055644818,
    -0.0110986309172066,
    0.00322534099559915,
    0.0679637405666789,
    -0.0126278937326607,
    -0.0235402812372355,
  ],
  [
    "VEGFR2",
    -0.0286920121860894,
    -0.0483547944860296,
    -0.00548237108990991,
    0.0286590579977999,
    -0.080026404616785,
    0.0231491771796964,
    -0.0288728331797853,
    0.0215668777185391,
    -0.0119678261918664,
    -0.0295738602999689,
    0.0609367361044545,
    -0.10310765214711,
    0.0118277724886162,
    -0.035075266821157,
    0.0725565144632851,
    -0.0188272734670684,
    0.0317954129706547,
  ],
  [
    "XRCC1",
    0.00352735163640741,
    -0.0060129615636746,
    0.00915921977380105,
    0.018123847456315,
    0.0641229496248267,
    0.000573004306455001,
    0.0239678870575281,
    -0.030155667845057,
    -0.0119239169394871,
    -0.0117964650474662,
    0.027828629662666,
    0.0679723536816024,
    -0.0191167286745249,
    0.0761061098818707,
    -0.0752923318011581,
    0.00935136789728549,
    -0.0244852541962319,
  ],
  [
    "YAP",
    0.0464391735537138,
    -0.0322812116033067,
    0.025498730080551,
    0.0175295748248512,
    -0.00748456229211608,
    0.0207629305843986,
    -0.0395151897059019,
    -0.00429325429948501,
    0.05886987099329,
    0.00588569320152927,
    0.0383643992225678,
    0.0421615191362471,
    0.0230627744093581,
    0.047983972103479,
    -0.0162616772890114,
    -0.0110654108066337,
    0.0410816174747135,
  ],
  [
    "YAP_pS127",
    -0.00829662608923598,
    -0.0398154843734176,
    -0.0178517423071652,
    0.0911711798340184,
    -0.146713610894155,
    -0.0230650528983076,
    -0.0376241848164912,
    0.0294569173051993,
    0.064290931863589,
    -0.0035091803378168,
    0.0805502647455292,
    0.0418944178338816,
    0.0605277636421574,
    0.00183346546167536,
    -0.0284278943220029,
    -0.0305975887344914,
    0.0704553488992363,
  ],
  [
    "YB1",
    0.0104409225986569,
    0.0394938417483295,
    -0.0224239903338106,
    0.00705305536412687,
    -0.0414987367025451,
    0.0650977568709821,
    0.00677085509459703,
    -0.0668895015555484,
    0.0761046666644937,
    0.00529353752676979,
    -0.00205334063577735,
    0.00738467431807157,
    -0.00432719476578474,
    -0.0652992491762028,
    0.012433188588883,
    -0.0687103609400602,
    0.0573094236466078,
  ],
  [
    "YB1_pS102",
    0.0137916338648864,
    0.02304053308124,
    -0.0140467976795821,
    0.00659493918202901,
    -0.048550322251551,
    -0.0476867524440522,
    -0.0173500129192101,
    0.0100612271404337,
    0.0162979236633803,
    0.0152253754631378,
    0.018266477821362,
    0.0429166412372396,
    0.016839466319171,
    -0.0906787087987612,
    0.0161182003734734,
    -0.0195552169550637,
    -0.0324313071944624,
  ],
  [
    "X4EBP1_pT70",
    -0.0318215331656983,
    0.0237947445513675,
    -0.00380668843971872,
    -0.0111449670281897,
    -0.000848257203251556,
    -0.0376069532533176,
    -0.00984787239618513,
    -0.0307975651020683,
    0.0164312492656119,
    0.0132462439585529,
    -0.0388648540128151,
    -0.00409371234283766,
    0.0202612196330384,
    -0.0207542376641396,
    -0.0343291027765231,
    -0.0220580471656994,
    -0.010522003193099,
  ],
  [
    "ARAF_pS299",
    0.017334273618245,
    -0.0170592274579797,
    0.0437648479314008,
    -0.0192076788201505,
    0.0443295249880591,
    -0.0440408381031431,
    0.0514401775307448,
    0.0120523426170121,
    0.00734095547919931,
    0.00463801528519536,
    0.00586556550896699,
    -0.0353523869606038,
    0.0476771793189221,
    0.00627671805308659,
    -0.0123522469728604,
    -0.0433314136459744,
    -0.000303396366633331,
  ],
  [
    "ANNEXINVII",
    0.0245919221430389,
    -0.0237577945316869,
    0.0450737403914021,
    0.00122250879852132,
    0.0144988087831884,
    -0.019850237011713,
    0.0154833679269993,
    0.00511689687372601,
    -0.0598225791704548,
    0.00114787456803686,
    -0.00218195085141931,
    -0.0300386447115089,
    0.0138020996949702,
    0.0114611713262815,
    0.00784508006171345,
    -0.0440627066795821,
    0.00867486462337731,
  ],
  [
    "ARID1A",
    0.0235604273502571,
    0.0120002192746679,
    -0.0221700492392796,
    0.0102413192968777,
    0.0234762467245781,
    0.0363332669793378,
    0.00727209949046013,
    0.00561635879338863,
    -0.00441266700575023,
    -0.00982245586357827,
    -0.0328616050855073,
    0.0515949572242979,
    -0.023647207235147,
    0.0527649106507746,
    0.0208343983131192,
    0.0151063023857251,
    -0.0354440793248053,
  ],
  [
    "BRAF",
    -0.0515698266429733,
    0.0224318318120419,
    -0.11072597787389,
    0.103327857127887,
    0.034088369804087,
    0.0669711022958026,
    0.0313100209362652,
    0.0355429393958605,
    -0.000716524689998991,
    -0.011572732860949,
    -0.0415627854415802,
    -0.0261591795155555,
    -0.0163756412520286,
    -0.000195527794449758,
    0.0957597705594334,
    -0.000393050112986725,
    -0.00721315232383349,
  ],
  [
    "BAD_pS112",
    0.0236834402482971,
    0.0211434842295032,
    -0.066453659944603,
    0.0635368248214853,
    -0.0290647540937354,
    -0.0353537724455293,
    0.00224696842240841,
    0.0476973719932675,
    -0.0245553655099606,
    0.0429509873344517,
    -0.00302140941704838,
    0.0327922609144997,
    -0.00417298405646366,
    -0.0124217455173523,
    0.00322828077516142,
    -0.0105847828098138,
    0.0430535899571634,
  ],
  [
    "BAP1C4",
    -0.0713537835392198,
    0.00985870202653908,
    -0.0598530566257907,
    0.0370440544025471,
    0.0665385376333565,
    0.0591512375264717,
    0.0625280720750283,
    -0.0146691042908998,
    0.0232471220348544,
    -0.0247347226119574,
    -0.0205389475801201,
    0.0199165822525404,
    0.022092791493097,
    -0.0275158026492138,
    0.000813056969766174,
    -0.0286056792349242,
    0.0146584852272062,
  ],
  [
    "CD20",
    0.0670469540445206,
    0.0100200859318722,
    -0.0225795789738351,
    -0.049755909145238,
    0.0237130764905277,
    0.0527209076079858,
    0.0331328222824659,
    -0.0501931123326004,
    -0.0104200786878856,
    0.0208482087159414,
    -0.0209223961700132,
    -0.0058761415628903,
    -0.0313378211599322,
    0.000798800506632279,
    -0.0857480756186497,
    -0.00652323427484894,
    0.0136580164388871,
  ],
  [
    "CYCLINE2",
    -0.000418580651557508,
    -0.00130917037242598,
    -0.00921683490328228,
    -0.010765320103864,
    0.0959010426347489,
    0.0343845017010474,
    0.0561612072670783,
    -0.0417231006435832,
    0.0259479676487743,
    -0.00898255996647657,
    0.00785484128967173,
    0.0018041021239269,
    0.00478159523517814,
    -0.0822455265824627,
    -0.0189623813978875,
    0.0265467323505607,
    0.0612785431172487,
  ],
  [
    "ETS1",
    0.0791956760739992,
    0.0731843241384346,
    -0.0139577208332024,
    -0.017658587686258,
    -0.0702234586519341,
    0.0242973707552855,
    0.012114300325798,
    -0.152162259506164,
    -0.0256177423881991,
    0.064542244223457,
    0.0328122095405293,
    -0.0344271454567246,
    -0.1964546355012,
    -0.0851470553702816,
    -0.0220926348954444,
    -0.149378651551543,
    -0.026269558295228,
  ],
  [
    "EIF4G",
    -0.108846233331655,
    -0.000458305746568233,
    -0.0692878091196017,
    0.0535406907578699,
    0.0087800199521089,
    0.120537301781445,
    0.029242204749886,
    0.0300741082277713,
    0.05022357832464,
    -0.0147856019867172,
    -0.118801022953083,
    0.013248669970549,
    0.0129074136704257,
    -0.026546653678485,
    -0.00611316505981612,
    -0.0372066094755882,
    0.00195700993473052,
  ],
  [
    "FASN",
    -0.270038787972887,
    0.0222318112495894,
    -0.049409089467233,
    -0.112706523417612,
    0.00648557959465411,
    -0.0695394510956519,
    -0.0191142316943513,
    -0.0525445382197982,
    0.0820196143926092,
    -0.0888359368709475,
    -0.0144623812372685,
    -0.0807813132143127,
    0.163775986216665,
    0.041993962696966,
    0.0157373940227468,
    0.139919583312951,
    -0.166625578018624,
  ],
  [
    "FOXO3A_pS318S321",
    0.0106734458720887,
    -0.00579302850272777,
    -0.00177381094615853,
    0.00230833049443348,
    -0.0163878111861669,
    -0.0347601971178788,
    0.00410064498887627,
    0.0130444171834145,
    0.00405297503802501,
    0.0186754489352284,
    -0.00326206542861263,
    -0.0148071252753682,
    0.000243635920055696,
    -0.00642324957001441,
    -0.0221142064929258,
    -0.01609488798154,
    0.0160955607833931,
  ],
  [
    "FOXM1",
    -0.0388288466123244,
    0.00835733645096612,
    0.024393420550255,
    -0.0405192395687184,
    0.0943947145917845,
    -0.00850658238020906,
    0.0822760117041956,
    -0.0239624054303837,
    0.0576833579369418,
    -0.00645244810576838,
    -0.0502434894782355,
    0.0124257035091102,
    0.0493447647057658,
    -0.0636578759607459,
    -0.0151783422307739,
    -0.005870366396036,
    -0.0622010419812712,
  ],
  [
    "G6PD",
    0.060615513818023,
    0.00999394752508629,
    0.0627683184949386,
    0.00929132300634971,
    0.0427108843999405,
    -0.0442081724682324,
    0.00508833663981455,
    -0.0308830338736909,
    -0.0870436912450785,
    -0.0261661907506247,
    -0.00417058314973245,
    -0.0448608337645945,
    -0.0594882052954969,
    -0.0293698606669391,
    0.0929612620899725,
    -0.0428522769676834,
    -0.0156626707367489,
  ],
  [
    "GAPDH",
    -0.197559318497615,
    0.154254848807168,
    0.0242922734394197,
    0.0625566505343292,
    -0.00601555114258345,
    -0.13818619468614,
    0.0343193318740835,
    -0.111677689680564,
    -0.127260334241187,
    -0.171284316540613,
    0.216609778529718,
    -0.0131192733401697,
    -0.304304406683999,
    -0.0447363033753977,
    -0.0645168651978034,
    -0.155784753352826,
    0.117282945609632,
  ],
  [
    "GSK3_pS9",
    -0.0515700112508982,
    0.0537062235948292,
    -0.108055946306408,
    0.0890381116704918,
    -0.103004472173454,
    -0.206945107307124,
    0.0402411079912782,
    0.0969798536942022,
    0.05121067143855,
    -0.0155186538016483,
    -0.0294804933144492,
    0.018683950614128,
    -0.0459680843832058,
    -0.0653095722042101,
    -0.052849078403501,
    -0.000681381061027724,
    0.0347612910865871,
  ],
  [
    "HEREGULIN",
    0.0807092195206154,
    -0.0223418225075048,
    0.0137772617414211,
    -0.0797332757375612,
    0.0567862247919052,
    -0.0282223423356928,
    0.0347185771296119,
    -0.000463527913884693,
    0.00715074891940681,
    -0.00460892755860512,
    0.00607746974380606,
    0.028408246706386,
    -0.0207384603753437,
    0.0182116688299625,
    -0.0466012466499271,
    -0.0171059721053799,
    0.019936447341208,
  ],
  [
    "MYH11",
    0.0617801210482105,
    -0.119008248052679,
    0.0804629187010467,
    -0.171447443300022,
    -0.483058298808707,
    0.187532586777741,
    0.50632105807335,
    -0.273554345047265,
    -0.150155493364927,
    -0.275159818418808,
    0.00392994454059139,
    0.106527999374053,
    0.108725250292921,
    -0.133431969668571,
    0.0433346318919189,
    0.161676566311934,
    0.0701684882156523,
  ],
  [
    "MYOSINIIA_pS1943",
    -0.0393495460352902,
    -0.0079067094944456,
    -0.0119194536809245,
    0.0198242944100367,
    -0.0523739097840437,
    -0.00211347323135565,
    -0.0348912503849309,
    -0.00437722889065525,
    -0.0751595199281479,
    -0.0687679973040758,
    -0.0272749899788617,
    -0.00367746073034076,
    -0.0467261040180428,
    0.0253801382824969,
    0.0488125588919719,
    -0.0588908460270776,
    -0.0732112584929267,
  ],
  [
    "NRAS",
    0.0312796515222816,
    -0.0207220164960389,
    0.0433710828721899,
    -0.041364509578319,
    0.0490848290884531,
    -0.0259404823752511,
    0.0365111833651147,
    0.0122223365543665,
    0.0133425467412884,
    0.022225421168104,
    -0.0130689912884789,
    -0.0141937895855234,
    0.0344854091487617,
    0.0112416465809109,
    0.0126693764266361,
    0.00455504433444664,
    0.0189036332499623,
  ],
  [
    "NDRG1_pT346",
    0.0146071777383788,
    0.13870825173038,
    0.0419527725401146,
    0.0101422666834029,
    -0.0637756778577148,
    -0.235504593634036,
    0.192457853000828,
    0.144535743414928,
    0.159411463093343,
    -0.0973850773392739,
    -0.0258724654527372,
    -0.277325256330616,
    -0.0927062615775246,
    0.179791978959419,
    -0.0437473506599481,
    0.0269369440452391,
    0.0924186029292602,
  ],
  [
    "P21",
    -0.0303046323947901,
    0.0207135032067991,
    0.0359464816052715,
    -0.0255653295475085,
    -0.0515023501148921,
    -0.0160888737273459,
    -0.0317113532592253,
    -0.00208794462357963,
    0.0205840800282827,
    0.00300434823734011,
    -0.00735067048074259,
    0.0130559289871843,
    -0.0573803327275234,
    -0.0474299400069976,
    -0.00600493827736479,
    -0.0287037033325984,
    -0.0536766304858877,
  ],
  [
    "P27_pT198",
    0.00581317888385537,
    0.00358506569469854,
    0.00618803509679637,
    -0.0376104013151698,
    0.00630656242976936,
    0.0113595398602528,
    -0.0104137465439882,
    0.0015639517414372,
    -0.00710264048472237,
    0.0469825347513343,
    -0.0447402334451862,
    -0.00837052497977416,
    -0.0153608914387273,
    -0.0161766807275043,
    -0.00627939068795027,
    0.0317539877148456,
    0.00568605112672727,
  ],
  [
    "P90RSK",
    0.0143332215725808,
    -0.0469075845507575,
    -0.0203489253796025,
    0.0271514891647186,
    0.0502076744010049,
    -0.0280378108127061,
    0.00604939169286054,
    0.0216084812874107,
    -0.108341405819204,
    0.0282792836877357,
    0.0338265095700551,
    0.0123970106810498,
    0.00199039264582604,
    -0.0183445121959019,
    0.121055571068295,
    -0.0240363324781827,
    -0.0291618741986452,
  ],
  [
    "PDCD4",
    0.0593607178004868,
    -0.0406507384879815,
    -0.15183163431229801,
    0.164022536610686,
    -0.126981139667639,
    -0.0385405049894476,
    -0.0526241817986011,
    0.090692138473355,
    -0.137457252998496,
    0.133996812194599,
    0.122363468237812,
    0.110262551425613,
    0.136090825194139,
    -0.0478725463239127,
    -0.0985025874399151,
    -0.0229175620043096,
    0.0579767881084335,
  ],
  [
    "PDK1",
    0.0249045809517752,
    0.00565313056953318,
    0.0493010016939261,
    0.0134166334412689,
    0.0380422403130922,
    -0.0304598703761319,
    0.069347427250543,
    -0.010278724652282,
    -0.0599802834290773,
    -0.00402087316526001,
    -0.0389962447841397,
    0.0523492817755417,
    -0.0463271969768591,
    0.0669712249701714,
    -0.0228559628585707,
    -0.0191428914677952,
    -0.0468856384582069,
  ],
  [
    "PEA15_pS116",
    0.0762685655731285,
    0.0667430742742574,
    -0.0161361879780832,
    -0.0643223001705063,
    -0.0075167355746791,
    0.0359658822790719,
    -0.00609570108275544,
    -0.0900645633808091,
    0.0336616632286746,
    -0.029519805500732,
    0.0560692556539356,
    -0.0923588287349195,
    -0.132316987044819,
    -0.120619364783657,
    -0.206269384667642,
    -0.151906757332531,
    -0.0466273624896323,
  ],
  [
    "PI3KP85",
    -0.0262402586296346,
    0.0857208029768028,
    0.00842400269986021,
    0.0519248344677291,
    -0.0215124793042514,
    0.00880548856290815,
    -0.0362121039137608,
    -0.0414971354268191,
    -0.0686154236656185,
    -0.0153644635004205,
    -0.0567807058701764,
    -0.0847757829089598,
    0.0604188369469893,
    0.023127719643703,
    -0.00461596298152027,
    0.00745383263347931,
    -0.0169128883471383,
  ],
  [
    "PKCPANBETAII_pS660",
    0.0438458083243012,
    0.0673889077936656,
    -0.0828195876567168,
    0.164251232624316,
    -0.0389772993287419,
    -0.0268771106245404,
    -0.00840390063279626,
    0.0959328191885673,
    -0.13586498167787,
    0.00352673657207887,
    0.0311550411133588,
    0.00390104076142279,
    0.00223682708987065,
    -0.0612076133295138,
    0.0383885690551097,
    0.0358509822295563,
    -0.0272791974398435,
  ],
  [
    "PRDX1",
    0.0272271327978194,
    -0.00686971361659153,
    0.0413144744845273,
    0.016512643101336,
    0.0374764553957039,
    -0.0416790316468664,
    -0.00200352531579954,
    -0.0500960600512437,
    -0.0805393082572825,
    0.0667287465339777,
    0.0211122796014271,
    -0.0739798852742163,
    0.0139066366339394,
    -0.0319544243821673,
    -0.00365626704237711,
    -0.0528179689152344,
    0.0961091344874259,
  ],
  [
    "RAB11",
    0.0446931075489226,
    0.0104488008349371,
    0.0499444277512525,
    -0.00877291043851376,
    -0.0802138778124041,
    0.0092949646118371,
    -0.02530778090087,
    -0.037849903755388,
    0.048642424508792,
    0.0550767491875205,
    0.0299505687006953,
    -0.0210739119802656,
    -0.0373079435420838,
    -0.0256697029675126,
    0.0118198748004574,
    -0.0456213797041409,
    -0.033028316803451,
  ],
  [
    "RAB25",
    0.00154875781814154,
    -0.210966646811528,
    0.112637993712135,
    0.0438228151256703,
    0.115404408345711,
    -0.0835589985943629,
    0.112956314404994,
    0.0266656773897972,
    -0.0400843423223155,
    -0.0150192298411037,
    0.0476594517056331,
    -0.039339816312175,
    0.00754689850584142,
    0.00346068680040581,
    0.0104052732147929,
    -0.0491413248640952,
    0.0480578342806924,
  ],
  [
    "RAPTOR",
    0.00072903172158868,
    0.0309635094529475,
    -0.0345053444835396,
    0.0419163919494575,
    -0.0067084017799982,
    0.0255566295185529,
    0.00132325930517091,
    -0.0130148718266144,
    0.0164084928095087,
    -0.0144985336408656,
    0.0369105770250119,
    -0.0034795942076138,
    0.0195614071785919,
    0.00140387931424428,
    0.0291610953444639,
    0.0619044232919551,
    -0.0652920027639842,
  ],
  [
    "RBM15",
    -0.101332547994315,
    0.0087384871725418,
    -0.0921081462776868,
    0.105849055893847,
    0.0376469176964847,
    0.109107903061144,
    -0.00632285930219048,
    0.0243512022653758,
    -0.0209120868623783,
    -0.00222552154396249,
    0.039730911271239,
    0.0277463428583049,
    0.00284502031077547,
    -0.0614833014571813,
    -0.0605096381636862,
    0.0635791740111243,
    -0.0304379777197249,
  ],
  [
    "RICTOR",
    -0.0288662730263312,
    0.000458460192347997,
    -0.0513123677526092,
    -0.000528141885329737,
    -0.100193116337988,
    0.103055858401132,
    0.148466352081348,
    -0.0203737001301065,
    -0.0170252739156135,
    -0.065787046752492,
    -0.065074983035143,
    0.0477554821798619,
    0.054396605392113,
    0.0274218374333581,
    0.0700975340387415,
    0.0860933378155407,
    -0.00632135370034401,
  ],
  [
    "RICTOR_pT1135",
    0.0286245354014836,
    0.00245386476088244,
    -0.059423905303487,
    -0.0459641951432182,
    -0.0362131193990205,
    -0.00774705564049027,
    -0.0276555913589185,
    0.0222124276519859,
    0.0555764562784837,
    0.0183003207645543,
    0.00758002304522863,
    -0.032855870516951,
    0.0169209482778258,
    -0.0301808345582561,
    -0.0138561244519301,
    0.0255069442188352,
    0.00127744302775873,
  ],
  [
    "SCD1",
    0.0444176281702583,
    -0.0029124231774271,
    0.0781899412276328,
    -0.00582535157957479,
    0.13098402388111,
    -0.0849032815284713,
    0.114828098574968,
    -0.0342658822296695,
    -0.00510505692999811,
    -0.0226973162464802,
    -0.0194266096791188,
    0.0101155952551146,
    0.00659791222773447,
    0.0244083952974202,
    -0.0328568623094564,
    -0.0542417844220026,
    -0.0112941358420134,
  ],
  [
    "SF2",
    0.00714723591165083,
    -0.0171857453147791,
    0.0202766135915979,
    0.0128630064623205,
    0.0637597570511891,
    -0.034826231886706,
    0.0416059743885094,
    0.00160035888881035,
    -0.0319006723487514,
    0.013698961065375,
    -0.00790680493342643,
    0.0603075720928333,
    -0.0290151387474143,
    0.0808490720538568,
    -0.0445696772777224,
    0.0226941713965376,
    -0.0339374178331906,
  ],
  [
    "TAZ",
    0.0284973863540431,
    0.0270699694285554,
    -0.00862463853041583,
    -0.0536132057268603,
    -0.0326189829205686,
    0.0335802551983942,
    -0.0257794739562304,
    0.0151758035390199,
    0.0563768075601504,
    -0.0198080836185213,
    -0.0121482367400775,
    0.0114572595797534,
    -0.0225948412542323,
    0.00872082263175749,
    0.0422575232010414,
    -0.0246326216896664,
    -0.0177577646463624,
  ],
  [
    "TIGAR",
    0.409331123767085,
    -0.043412975629389,
    -0.378096467484143,
    -0.142544816027473,
    0.11554958980105,
    -0.0327901325348097,
    -0.178073115289404,
    0.0333441583205142,
    0.0176000394954131,
    -0.403902622483793,
    0.112585950689513,
    -0.177560658768287,
    0.110220109171619,
    0.000248693109928888,
    -0.166258115368174,
    0.176965221360299,
    -0.190411941637707,
  ],
  [
    "TRANSGLUTAMINASE",
    -0.0118197031979289,
    -0.0628137054543351,
    -0.0232407927207608,
    -0.0490998287069203,
    0.000484614665150835,
    0.0339402933394589,
    -0.03745731397796,
    0.00392294863871122,
    -0.0535154145287161,
    -0.0655708926206573,
    -0.0049958536898365,
    -0.035039285605833,
    -0.0699886133748649,
    -0.0608796284908219,
    -0.0195465596847796,
    -0.0084403741884418,
    0.00871362603381413,
  ],
  [
    "TFRC",
    -0.242694546980596,
    0.0392697590513962,
    0.0081879080293812,
    -0.106063809797828,
    0.0140915167177547,
    -0.103895540521701,
    -0.213337887710807,
    -0.195621589154876,
    -0.0391649570462377,
    -0.186165538544769,
    -0.0226192636781096,
    -0.00325271543044183,
    -0.0680131292708759,
    -0.0904777996873705,
    0.0833320940498673,
    0.0624119096825272,
    -0.0278559511046444,
  ],
  [
    "TSC1",
    -0.0514605381369408,
    0.00802173105628011,
    -0.0175344673958852,
    0.0331006591132654,
    -0.0399652676786394,
    0.0604530474328337,
    -0.00408672290812436,
    0.0253840421874184,
    -0.0185571786980362,
    -0.0344854630882582,
    -0.0116889128686777,
    -0.00346713851859259,
    0.00865914481035784,
    0.0497160068047431,
    0.0565097059218025,
    0.0428379800400913,
    -0.0225577899700036,
  ],
  [
    "TUBERIN_pT1462",
    0.0164163777880505,
    0.0521055644328462,
    -0.0629428180196698,
    0.0307846914405136,
    -0.000513565497572569,
    -0.109239479905657,
    0.0945693674088236,
    0.0326961787967351,
    0.0709168771489199,
    0.0216289492224151,
    -0.0678069850150852,
    -0.0387266029366277,
    -0.0312832180213928,
    -0.00124805184588346,
    0.00166371272568875,
    -0.00603995865641401,
    0.0231465656590522,
  ],
  [
    "EPPK1",
    -0.193142518303797,
    -0.497593870845726,
    0.202748001321372,
    0.208767261381581,
    -0.127484845188313,
    -0.0449655627818752,
    -0.0653965589054912,
    0.00440166872100095,
    0.0557432420227098,
    -0.131750284113436,
    -0.126936392073992,
    -0.281732916772027,
    0.0788924355760645,
    0.209496518666937,
    -0.10531492573107,
    -0.237223879931033,
    -0.154147936719715,
  ],
  [
    "XBP1",
    -0.00187463503854703,
    -0.00191323586342496,
    0.0369979131735626,
    -0.0143263500034789,
    -0.0303149606887339,
    0.00123022667369416,
    0.0305651759512706,
    -0.0348406439164791,
    0.0270702662090088,
    0.0333274176177624,
    0.039362954741598,
    -0.0283684823625111,
    0.0138119905006503,
    -0.0382877746736672,
    0.00456971398364028,
    -0.0344319117407435,
    -0.00921634344624642,
  ],
  [
    "ACETYLATUBULINLYS40",
    -0.0735180159493206,
    -0.0402298038856939,
    0.0385057807933129,
    0.0716984356499106,
    0.0902479922899098,
    0.0937724246155452,
    0.0747296792732065,
    0.0521310234792179,
    0.20412480161646,
    -0.0905164517386177,
    0.0825054086407001,
    -0.0268931091113962,
    -0.0892761984979347,
    -0.111941432483119,
    0.17095595057899,
    0.251427052955624,
    0.199367115000156,
  ],
  [
    "P62LCKLIGAND",
    -0.0343456455245501,
    0.0343573059057704,
    0.014192765120004,
    0.0525292424254614,
    0.0998935502048158,
    -0.0424165912335028,
    0.0730434258775625,
    -0.0631852037781692,
    -0.0846339335107904,
    -0.0413789279166979,
    -0.117279459231515,
    -0.00268934698135513,
    0.0296364321303715,
    -0.0720841475543054,
    0.0458273639412623,
    -0.0392599143948416,
    -0.0230930448277138,
  ],
  [
    "X1433BETA",
    0.0428986496184288,
    -0.0149983600540365,
    0.0348982984090045,
    -0.0184461927599432,
    0.0331875254228097,
    -0.016079738575489,
    0.0258515444818702,
    0.00803087648750194,
    -0.00511730789126107,
    0.0219835247767684,
    -0.013813663880926,
    0.0139071376690329,
    -0.0156071332220128,
    0.0185508377231258,
    0.000232253618421865,
    0.00676080506947073,
    -0.0684613392120865,
  ],
  [
    "X1433ZETA",
    -0.0669565576089319,
    0.0220402471276796,
    0.0131492656310552,
    -0.00536687511812268,
    -0.0445246149684544,
    -0.00277692542090001,
    -0.0782526376382599,
    -0.0494960841714249,
    -0.0247490731677678,
    -0.0382121105538796,
    0.0447658660844275,
    0.00734411038532491,
    0.0152979118244633,
    0.0279151741683634,
    -0.0383279669573878,
    -0.0369981974363013,
    0.0220250107117114,
  ],
  [
    "ACVRL1",
    0.0454484925330217,
    -0.00164241831111401,
    0.0692493103677944,
    -0.0205452719920698,
    0.0414642929688722,
    -0.0272477952909548,
    0.0496385679152907,
    0.0207680122697066,
    -0.0298744588303153,
    0.032242512000578,
    -0.0272913954927006,
    0.0112682886941677,
    -0.0222635398793235,
    0.0276641156356646,
    0.00454270217918056,
    -0.00435725989375206,
    -0.0546994762560648,
  ],
  [
    "DIRAS3",
    0.046698392033799,
    -0.032082604098292,
    -0.00507610301755384,
    -0.042465642577292,
    0.0299236206738301,
    -0.0184259841688838,
    0.0134632757956487,
    0.0253072111723487,
    0.0245153075762477,
    0.00971894354414992,
    0.0131032900931096,
    -0.0106546906285762,
    0.0192427721004628,
    -0.0110485523162742,
    0.070197795517881,
    0.00396852465929755,
    0.0212215600755467,
  ],
  [
    "ANNEXIN1",
    0.13373781533754,
    0.0000969988870681349,
    0.23991403522676,
    0.290840181719405,
    -0.0750002191295617,
    0.0729169788828643,
    -0.127108031582149,
    0.172099394820843,
    0.054117585302946,
    -0.214533375702931,
    0.0173395990905354,
    -0.0487833886323215,
    0.166818366405129,
    0.0475431847106861,
    -0.0673681811672702,
    -0.0322378892977331,
    0.105301034354446,
  ],
  [
    "PREX1",
    0.0267141416769809,
    0.0787282079920372,
    0.0836337587424588,
    0.041821529682702,
    0.0636922987481322,
    -0.0224345255750831,
    0.079801132652337,
    0.0668039223878003,
    -0.179972314599104,
    -0.026357626291087,
    -0.0755710217212165,
    -0.0647007844259951,
    -0.0766266247471564,
    0.0672543658644582,
    0.0484233626815231,
    0.0675339889654495,
    -0.138497567283663,
  ],
  [
    "ADAR1",
    -0.0205795814190447,
    0.020440449224279,
    0.00283247052449543,
    0.0253664367250844,
    0.0724679907272662,
    0.00940234103775849,
    0.0291033888539408,
    -0.00147881749567394,
    0.0593724680613243,
    0.000399034345529188,
    -0.0272743730210211,
    -0.0427975037457741,
    0.0725846996786366,
    -0.060270184963228,
    0.0151733776470384,
    -0.00463137223583064,
    0.0785985795322844,
  ],
  [
    "JAB1",
    -0.0175730714110476,
    -0.0159880239466098,
    0.0563794195362399,
    -0.00296573793873079,
    0.0624430463471685,
    -0.0464293015165397,
    0.0499871296459603,
    0.00682513910334515,
    0.0161493199440172,
    -0.00169220411440569,
    0.0651636648864331,
    -0.0686634062060142,
    0.0674221393524956,
    -0.0425172991567604,
    0.052609561957971,
    -0.0503112007158767,
    0.0511890697979511,
  ],
  [
    "CMET",
    0.0486258816785674,
    0.00917839894075951,
    -0.00359633644546477,
    -0.0176451410145455,
    -0.0177156698533106,
    -0.00537087311906766,
    -0.00680737121199829,
    -0.0245012941302012,
    0.0346457787454759,
    0.0299405407527175,
    0.0124836010474526,
    0.00514744164351968,
    -0.0118024708472161,
    -0.0113957105524665,
    0.0331005887658863,
    0.0109259289458165,
    0.0258693445640987,
  ],
  [
    "CASPASE8",
    -0.0150904390508713,
    0.0145694653305662,
    0.0193438996953709,
    -0.048442850847086,
    -0.0144205176983979,
    -0.00443549938353859,
    -0.0177530998153054,
    -0.00574235651487602,
    0.0248034097203636,
    0.0102294110238427,
    0.00286061538818222,
    -0.064259177703093,
    -0.0140310345886606,
    -0.0409551154507884,
    0.0034071373511782,
    -0.00205970893529134,
    0.0580648807264916,
  ],
  [
    "ERCC1",
    0.0769702510923623,
    0.00803739009147566,
    -0.0462163809024785,
    -0.0371648139079963,
    0.0405379703062237,
    0.00571085034107571,
    0.0161902526243403,
    -0.027415283705287,
    0.000503553221660623,
    -0.0527675006435728,
    0.00817136263775011,
    0.00141307437869201,
    -0.0344960168392446,
    0.0185778263694848,
    -0.029750416784015,
    -0.0104722927928475,
    -0.0277640610959054,
  ],
  [
    "MSH2",
    -0.0313647691945865,
    -0.00374684690008185,
    -0.0067880503797344,
    0.0443135251669136,
    0.0712371842468785,
    0.00954329077008336,
    0.0218412464592334,
    0.0110734909793242,
    0.00147384103223285,
    -0.0144089280257764,
    0.029419711503161,
    0.0423390226470902,
    0.0436237994949756,
    0.0127530044347466,
    0.0242254862709016,
    0.0448355344422696,
    -0.00781477252604093,
  ],
  [
    "MSH6",
    -0.0928560594262466,
    0.0136550464540449,
    -0.082878363908927,
    0.0175059847733446,
    0.0409336926891779,
    0.0914355610052355,
    0.0066451067237018,
    -0.0207165033137017,
    0.0390047155909988,
    -0.0500634046275343,
    0.00114785824133128,
    0.0626462461764034,
    -0.00145768317846804,
    -0.0694152170394552,
    -0.0174099151496227,
    -0.0343555781673601,
    -0.0117607439242438,
  ],
  [
    "PARPCLEAVED",
    0.0089383009935316,
    -0.00694096776433702,
    0.0305921850832459,
    -0.0263673064343814,
    0.0605658842291467,
    -0.0141235702104413,
    0.0440874533335665,
    -0.00979280779834776,
    0.0240987659252683,
    0.0234209436028664,
    -0.00519519901006894,
    0.00425130547974459,
    0.032042161355337,
    0.0129116054239112,
    0.00387376795977611,
    -0.010792322517627,
    0.0236125533481697,
  ],
  [
    "RB",
    0.054420883291336,
    0.0226570199823507,
    0.00528946343058182,
    -0.0387176455223589,
    -0.0177933265289952,
    -0.0131427535301155,
    -0.0149096391783209,
    -0.0366987803778747,
    0.0482278140014776,
    0.020508758254344,
    0.0026088437743764,
    0.00938443041139353,
    -0.0464889472795736,
    0.000931740694075879,
    0.0240335894677169,
    -0.0063676533849718,
    -0.0108326402705028,
  ],
  [
    "SETD2",
    0.018988993752313,
    -0.0114683669205314,
    0.0263621171612542,
    -0.0418832991559681,
    -0.000873334198620362,
    0.000329065039224111,
    -0.020591806744682,
    -0.00724232399801329,
    0.0341956323544576,
    0.0305918681225454,
    0.000957293442982197,
    -0.00279772196656829,
    0.0255385033072924,
    -0.00950241425099324,
    0.0202237747189073,
    -0.00311404753209414,
    0.0124738860062481,
  ],
  [
    "SMAC",
    0.0417184556953478,
    0.0201087428745143,
    0.139053667106771,
    0.0991362871194261,
    0.152580545447961,
    -0.0660469413192994,
    0.131079901615314,
    -0.0606576091990668,
    -0.0283642423677788,
    -0.0376648534011299,
    0.0908569192478639,
    0.0711937209306651,
    -0.0271365360325358,
    -0.0271844608122352,
    -0.0850725939933627,
    -0.0541539864818724,
    0.0997009715193965,
  ],
  [
    "SNAIL",
    0.0402048750639895,
    0.00171176642824927,
    0.0266845164613028,
    -0.0470857198672162,
    0.0193740181293151,
    -0.00705002864596699,
    0.0228974734409161,
    -0.0156523877619528,
    0.0312959839415148,
    0.00951689997700717,
    -0.0103547228269717,
    0.04723090302733,
    -0.0491507853386359,
    0.0315871635092062,
    0.0133731928441871,
    -0.00419380586619372,
    -0.00352289251833227,
  ],
  [
    "BRD4",
    -0.107085604328595,
    0.0228144046322047,
    -0.0710820213279517,
    0.0563229825981475,
    -0.00545549360954204,
    0.159724827688387,
    0.00241928038836077,
    -0.0377610038252582,
    -0.0738788912319524,
    0.036899104995498,
    -0.121534108565673,
    0.225328112598126,
    -0.0939164078149146,
    0.435135125532907,
    -0.337437595278747,
    0.211252836749231,
    0.0684441553586576,
  ],
  [
    "CDK1_pY15",
    -0.000608214647296029,
    0.00639183650686212,
    0.00801392667655367,
    0.0259021624561992,
    0.00695502304188207,
    -0.049820240525113,
    -0.000970439155933376,
    -0.0453251518104815,
    0.0340417415714276,
    0.0499048837503874,
    -0.0790178362534186,
    0.117641926755382,
    0.123233047913736,
    0.0955798053721154,
    -0.114220283247954,
    0.080538855819446,
    0.12085498660188,
  ],
  [
    "ARAF",
    -0.0318796849816215,
    -0.0228984100037726,
    -0.0235820624562198,
    0.00183260070707749,
    -0.0115570472276904,
    0.018411800621838,
    -0.0350792982106445,
    0.0395346784208213,
    -0.0629609178741381,
    0.0248795684533779,
    -0.0180926988084283,
    0.00990176848357764,
    0.0546679829148086,
    0.0712271164450538,
    0.0106733670453988,
    0.0433879903688077,
    0.0278220399377642,
  ],
  [
    "BRAF_pS445",
    -0.0240419377915493,
    0.00704486819323405,
    -0.0266092480678891,
    0.0481245908796156,
    -0.00118884943468833,
    -0.0858548726668461,
    -0.0025184593724842,
    0.0155256384030555,
    -0.0818094339480241,
    -0.00184378539938356,
    0.06954594428688,
    0.0202127264077272,
    0.0302968435529352,
    0.0256231968784364,
    0.0767544036035904,
    -0.00774872706979115,
    -0.00831165814131144,
  ],
  [
    "BCL2A1",
    -0.00562045901918004,
    -0.00210785103834757,
    0.0115308695454145,
    -0.0577241085238873,
    -0.0483728312804388,
    0.000371921317384079,
    -0.0679369973855284,
    -0.00548626313157744,
    0.0304529153090498,
    0.0417568986966125,
    0.00290082657733759,
    -0.0165238383914078,
    0.020444078706083,
    0.0202870380569522,
    -0.00917605988443399,
    0.0150216149956697,
    -0.00764535861969011,
  ],
  [
    "CABL",
    -0.0202267442000929,
    -0.00173947389857506,
    -0.00498186113179504,
    -0.0112710354988969,
    -0.0274048943206425,
    0.00598978553936422,
    -0.0497019899097917,
    -0.00999370320691635,
    0.00125972825752671,
    0.0206808074750001,
    0.0166228056340347,
    0.0266350284462969,
    0.00409001458892985,
    0.0573203986631485,
    -0.0266024248976725,
    0.0197453599502402,
    -0.00358599829872579,
  ],
  [
    "CASPASE3",
    -0.0329227547597663,
    -0.0160861452157162,
    0.000151493901032758,
    -0.0183428874310778,
    0.0248613491101371,
    0.00158729935415596,
    -0.0314674916826139,
    0.00408165982841351,
    0.00647626567992858,
    0.0174711576964517,
    0.0128143140269012,
    0.0140527656338665,
    0.0481412870136509,
    0.039338741609723,
    -0.003488526338992,
    0.0414442415684115,
    0.0712612991184353,
  ],
  [
    "CD26",
    -0.00664347157587837,
    -0.0119786804202146,
    0.00794595555120191,
    -0.00405862967383267,
    -0.0502665677124525,
    0.00363267528184238,
    -0.0411594076348255,
    -0.0151952592380405,
    0.0202279688272756,
    0.00611173464489717,
    0.0348359044522171,
    -0.0280574524053731,
    0.0431895821433117,
    0.0112331861913177,
    -0.00741428695674201,
    -0.0103887501926173,
    0.0445053742441647,
  ],
  [
    "CHK1_pS296",
    -0.00595503743434298,
    -0.0039177562285022,
    -0.00176104987145165,
    -0.0409904347442283,
    -0.0208775208688746,
    0.00168994512214992,
    -0.047971514329968,
    0.00268485777154592,
    -0.00872215083715741,
    0.0160086604663064,
    -0.0157626893425959,
    0.00229164217854271,
    0.000296496605594346,
    0.0107410325368561,
    0.0086598038166833,
    0.0325149392277099,
    -0.0221358805428246,
  ],
  [
    "COG3",
    -0.0256177128906409,
    -0.0122033373269637,
    -0.0370983808606244,
    0.0495854777129918,
    0.0514126397844858,
    0.0021772324077028,
    0.0253248405047173,
    0.00233759524940556,
    -0.0159583849924336,
    -0.0120038142481917,
    0.0264008676816764,
    -0.0131423225034091,
    -0.0300357360232756,
    -0.0309693122880493,
    0.0576663994967708,
    0.0228408197509583,
    -0.0316402130034582,
  ],
  [
    "DUSP4",
    0.0401488220115446,
    0.255813074508881,
    0.0777272576098117,
    0.105092143333137,
    0.107997496781026,
    -0.0814727174912493,
    0.0235206613144582,
    -0.00890059610887488,
    0.0421612672040943,
    -0.153201520333521,
    -0.0436519412093853,
    -0.0389612603661033,
    0.171260098486441,
    0.0447991928476787,
    -0.0366326355215905,
    0.0456052467089158,
    -0.0152409183184684,
  ],
  [
    "ERCC5",
    -0.0564434562279539,
    0.0224635626479133,
    -0.0143480570561246,
    -0.00595254126533576,
    -0.0441471478234434,
    -0.0195274267479682,
    -0.0271798658743975,
    -0.0406877478029219,
    -0.0317027770474176,
    0.0395435000363073,
    -0.0651295691158179,
    0.110927776634221,
    -0.0463916736964153,
    0.167805627595976,
    -0.140654389594483,
    0.0852972769787228,
    0.0543518662030282,
  ],
  [
    "IGF1R_pY1135Y1136",
    0.00439787812268546,
    -0.00926693766147847,
    0.0046375330872553,
    -0.0362796243733171,
    -0.0346603650305491,
    -0.00671470074551378,
    -0.0477516465095991,
    0.0246184597247214,
    0.0243041806646712,
    0.0413081144630582,
    0.0101144010906878,
    -0.0107419440099916,
    0.060142618118643,
    0.0414927929581559,
    -0.000906431341578075,
    0.0294790568925422,
    0.0245498608363695,
  ],
  [
    "IRF1",
    -0.012184266647552,
    0.0205472412619981,
    -0.00386110855507922,
    -0.0266907768196717,
    -0.0444476847928868,
    0.00962019503090995,
    -0.0471531728774736,
    -0.0324923761961695,
    -0.0144182783830793,
    0.0484495900897014,
    -0.0298304162165739,
    -0.0498083831266676,
    0.00481756554384192,
    0.022756481560398,
    -0.0820947752085832,
    0.0173036174662079,
    0.0274862576286124,
  ],
  [
    "JAK2",
    -0.0314364335271018,
    0.0144683687954571,
    -0.0271998423038057,
    0.030311728987323,
    0.00454869426558355,
    0.0373032034516075,
    -0.00503510181150279,
    -0.0197825963916749,
    -0.0557278144296715,
    0.0085715759844756,
    -0.0259528427447747,
    -0.000765358146126476,
    -0.00778972121892604,
    0.0293593341187407,
    -0.0496949494642747,
    0.0553424196565713,
    0.0269504392837337,
  ],
  [
    "P16INK4A",
    -0.083151810642094,
    0.00745319075004846,
    0.0858549506694875,
    -0.0111620203455276,
    0.0301955664948086,
    -0.0486912110088435,
    -0.0290396651336147,
    -0.0758945455578018,
    0.0683933692853786,
    -0.0455727720214874,
    -0.0207868979207693,
    -0.00886379181859427,
    0.0829893398694483,
    -0.110109854815753,
    -0.00567951039906543,
    -0.0667828454447881,
    -0.0499788796325857,
  ],
  [
    "SHP2_pY542",
    -0.0289946764965878,
    -0.0513441821642228,
    -0.033880133152334,
    -0.00196265686821549,
    -0.0662207718090398,
    -0.012577083043395,
    -0.035785501337526,
    0.0855549835955657,
    0.025242357764428,
    0.0464962928899571,
    0.0249287149692435,
    -0.0262754971905503,
    0.111610264531322,
    0.0425404646126205,
    -0.0240915953667441,
    0.0387254337360499,
    0.117657888290952,
  ],
  [
    "PDL1",
    0.0114390827090707,
    0.00878879685596446,
    0.0542012786134766,
    -0.0377337461908695,
    -0.00552625397898491,
    0.010836828155608,
    -0.042336809947784,
    0.0191366819089145,
    -0.0222339029272212,
    0.0977712393102589,
    -0.0636741355431672,
    -0.0977512175659688,
    0.0710931953163271,
    -0.0644935072790989,
    -0.0184968689224659,
    0.0605514187221616,
    0.146420044278676,
  ],
];

export const headers = [
  "UID",
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
  "G6",
  "G7",
  "G8",
  "G9",
  "G10",
  "G11",
  "G12",
  "G13",
  "G14",
  "G15",
  "G16",
  "G17",
];
